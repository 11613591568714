import axios from "axios";
import { getDefaultStore } from "../../utils/storeUtil";

export const fetchNotifications = async ({ page, size }) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/announcements?page=${page}&size=${size}&sort=createdAt,desc`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log({ err });
    return [];
  }
};
export const newNotificationsCount = async (createdAoe) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/announcements/count?status=UNREAD`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log({ err });
    return 0;
  }
};
export const readNotification = async (notificationId) => {
  try {
    let accessToken = localStorage.getItem("access_token");
    const store = getDefaultStore();
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/store/${store.store_id}/announcements/${notificationId}/read`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (err) {
    console.log({ err });
    return {};
  }
};

import React, { useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../../components/common/ConfirmModal";
import { EditIcon, ExpandIcon } from "../../../../icons";
import CancelOutlinedIcon from "../../../../icons/CancelOutlinedIcon";
import {
  deleteMenuItem,
  deleteSubMenuItem,
} from "../../../../redux/menu/menuActions";
import {
  rowFlexAlignCenter,
  rowFlexAlignStart,
  rowFlexCenterAll,
  rowFlexJustifyBetween,
} from "../../../../styles/common";
import useStyles from "./styles";
import ReOrderSharpIcon from "../../../../icons/ReOrderSharpIcon";
import { CancelOutlined } from "@material-ui/icons";

const MenuItemCard = ({
  menu,
  menuItem,
  handleProps,
  subMenuItems,
  setMenuAnchor,
  setSelParentMenuItem,
  setEditItemRecord,
  setToastDel,
  setToastErr,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuSubMenuItems, setMenuSubMenuItems] = useState([]);
  const [deletingItem, setDeletingItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [delSubMenuItem, setDelSubMenuItem] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); // State to track expansion

  useEffect(() => {
    setMenuSubMenuItems(subMenuItems);
    // eslint-disable-next-line
  }, [subMenuItems]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const deleteItemConfirmPopUp = (item) => (event) => {
    event.stopPropagation();
    setDeletingItem(item);
  };

  const deleteItemConfirm = async () => {
    setLoading(true);
    if (delSubMenuItem) {
      await dispatch(
        deleteSubMenuItem(menu.id, menuItem.id, deletingItem.id)
      ).then((res) => {
        if (res) {
          setToastDel(true);
        } else {
          setToastErr(true);
        }
      });
    } else {
      await dispatch(deleteMenuItem(menu.id, deletingItem.id)).then((res) => {
        if (res) {
          setToastDel(true);
        } else {
          setToastErr(true);
        }
      });
    }
    setDeletingItem(null);
    setLoading(false);
  };

  return (
    <>
      <div
        className={rowFlexAlignStart}
        style={{ marginBottom: 12 }}
        onClick={toggleExpand}
      >
        <span
          style={{
            fontSize: "24px",
            marginRight: "16px",
            cursor: "grab",
            color: "#999999",
          }}
          {...handleProps}
        >
          <ReOrderSharpIcon />
        </span>
        <div
          style={{
            padding: "4px 8px",
            border: "1px solid #E1E1E1",
            paddingBottom: isExpanded ? "0px" : "4px",
            borderRadius: 6,
            width: "100%",
          }}
        >
          <div className={rowFlexJustifyBetween}>
            <Typography style={{ fontSize: 14 }}>{menuItem.name}</Typography>
            <div className={rowFlexJustifyBetween}>
              <div className={rowFlexJustifyBetween}>
                <span
                  style={{
                    cursor: "pointer",
                    marginLeft: 16,
                  }}
                  onClick={() => {
                    setSelParentMenuItem(null);
                    setMenuAnchor(true);
                    setEditItemRecord({
                      record: menuItem,
                      parentMenuItem: null,
                    });
                  }}
                >
                  <EditIcon size={15} />
                </span>
                <Button
                  style={{
                    cursor: "pointer",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                  disabled={subMenuItems}
                  onClick={(event) => {
                    deleteItemConfirmPopUp(menuItem)(event);
                    setDelSubMenuItem(false);
                  }}
                >
                  <CancelOutlinedIcon
                    color={!subMenuItems ? "#1641db" : "#999999"}
                  />
                </Button>
              </div>
              <span
                style={{
                  cursor: "pointer",
                }}
              >
                <ExpandIcon />
              </span>
            </div>
          </div>
          {isExpanded && (
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                borderTop: "1px solid #E1E1E1",
              }}
            >
              <Droppable
                droppableId={`${menuItem.name}-${menuItem.id}`}
                type={`${menuItem.id}`}
                style={{ margin: 0 }}
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      padding: 8,
                      borderRadius: 6,
                    }}
                  >
                    {menuSubMenuItems?.length !== 0 &&
                      menuSubMenuItems?.map((item, i, { length }) => (
                        <Draggable
                          key={`${item.id}${i}`}
                          draggableId={`submenuitem-${item.id}${i}`}
                          index={i}
                        >
                          {(provided) => (
                            <>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  userSelect: "none",
                                  padding: 0,
                                }}
                              >
                                <div className={rowFlexAlignCenter}>
                                  <div
                                    {...provided.dragHandleProps}
                                    style={{
                                      fontSize: "20px",
                                      marginRight: "16px",
                                      cursor: "grab",
                                      color: "#999999",
                                    }}
                                  >
                                    <ReOrderSharpIcon />
                                  </div>
                                  <div
                                    className={rowFlexJustifyBetween}
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <Typography style={{ fontSize: 13 }}>
                                      {item.name}
                                    </Typography>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          marginRight: 16,
                                        }}
                                        onClick={() => {
                                          setSelParentMenuItem(null);
                                          setMenuAnchor(true);
                                          setEditItemRecord({
                                            record: item,
                                            parentMenuItem: menuItem,
                                          });
                                        }}
                                      >
                                        <EditIcon size={15} />
                                      </span>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={(event) => {
                                          deleteItemConfirmPopUp(item)(event);
                                          setDelSubMenuItem(true);
                                        }}
                                      >
                                        <CancelOutlinedIcon color="#1641db" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {i + 1 !== length && (
                                <hr className={classes.divider} />
                              )}
                            </>
                          )}
                        </Draggable>
                      ))}

                    {subMenuItems && <hr className={classes.divider} />}
                    <span
                      className={`${rowFlexCenterAll} ${classes.clickableHeading}`}
                      style={{ fontSize: 14, fontWeight: 600 }}
                      onClick={() => {
                        setMenuAnchor(true);
                        setSelParentMenuItem(menuItem);
                        setEditItemRecord({
                          record: null,
                          parentMenuItem: null,
                        });
                      }}
                    >
                      + Sub menu item
                    </span>
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </div>
      </div>

      <ConfirmModal
        open={deletingItem ? true : false}
        message={
          <span>
            Are you sure you want to delete <b>{deletingItem?.name}</b> menu
            item?
          </span>
        }
        loading={loading}
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setDeletingItem(null);
        }}
        onConfirm={deleteItemConfirm}
      />
    </>
  );
};

export default MenuItemCard;

import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Controls from "../../../../components/controls/Controls";
import CombinedButtons from "../../../../components/buttons/CombinedButtons";
import {
  createCollectionOptions,
  createPageOptions,
  menuItemOptions,
} from "./Constants";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addMenu,
  addMenuItem,
  addSubMenuItem,
  editMenuItem,
  editSubMenuItem,
} from "../../../../redux/menu/menuActions";
import { fetchCategories } from "../../../../redux/category/categoryActions";
import { getCollections } from "../../../../redux/collections/collectionActions";
import { getStoreIdFromUrl } from "../../../../utils/storeUtil";
import axios from "axios";
import { useEffect } from "react";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import SolidButton from "../../../../components/buttons/SolidButton";
const initState = {
  entity_type: "none",
  entity_id: "none",
  name: "",
  link: "",
  open_new_page: false,
};

const MenuDrawer = ({
  setMenuAnchor,
  menu,
  selParentMenuItem,
  editItemRecord,
  setToastSucc,
  setToastSuccMsg,
  setToastErr,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const store = state.store.store;
  const categoriesState = state.categories;
  const categories = categoriesState.categories;
  const categoryOptions = categoriesState.categoryOptions;
  const collectionStore = state.collections;
  const storeId = getStoreIdFromUrl();
  const [pages, setPages] = useState([]);
  const [formData, setFormData] = useState(initState);
  const [loading, setLoading] = useState(false);
  const collectionOptions = createCollectionOptions(collectionStore.list);
  const [initialState, setInitialState] = useState({});
  const pageOptions = createPageOptions(pages);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
    }
    if (collectionStore.list.length === 0) {
      dispatch(getCollections());
    }
    if (store.slug) {
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/org/store/${store.slug}/pages`
        )
        .then((res) => {
          let data = res.data.payload;
          setPages(data);
        });
    }
    // eslint-disable-next-line
  }, [storeId, store]);

  useEffect(() => {
    if (editItemRecord.record) {
      let rec = editItemRecord.record;
      const initialCss = {
        entity_type: rec.entity_type,
        entity_id: rec.entity_id,
        name: rec.name,
        link: rec.link,
        open_new_page: rec.open_new_page,
      };
      setFormData(initialCss);
      setInitialState(initialCss);
    }
    // eslint-disable-next-line
  }, [editItemRecord]);

  const disabled =
    formData.entity_type === "none" ||
    (formData.entity_type !== "CUSTOM" && formData.entity_type === "none") ||
    formData.name === "" ||
    ((selParentMenuItem || editItemRecord.parentMenuItem) &&
      formData.link === "");

  const onFormFieldChange = (fieldName, fieldValue) => {
    let clonedState = { ...formData };
    clonedState[fieldName] = fieldValue;
    if (fieldName === "cat_entity") {
      let name = categoryOptions.find((item) => item.id === fieldValue)?.title;
      let link = `/categories/${fieldValue}`;
      clonedState["entity_id"] = fieldValue;
      clonedState["name"] = name;
      clonedState["link"] = link;
    }
    if (fieldName === "coll_entity") {
      let collection = collectionOptions.find((item) => item.id === fieldValue);
      let name = collection?.title;
      let slug = collection?.slug || collection.id;
      let link = `/collections/${slug}`;
      clonedState["entity_id"] = fieldValue;
      clonedState["name"] = name;
      clonedState["link"] = link;
    }
    if (fieldName === "page_entity") {
      let name = pageOptions.find((item) => item.id === fieldValue)?.title;
      let link = `/${
        fieldValue === "terms-and-conditions" ? "tos" : fieldValue
      }`;
      clonedState["entity_id"] = fieldValue;
      clonedState["name"] = name;
      clonedState["link"] = link;
    }
    setFormData(clonedState);
  };

  const onHandleSubmit = async () => {
    setLoading(true);
    if (editItemRecord.record) {
      await editingMenuItem(menu.id);
    } else {
      if (!menu?.id) {
        let payload = {
          name: "Header Menu",
          type: "header",
          items: [],
        };
        await dispatch(addMenu(payload)).then((res) => {
          if (res?.id) {
            addingMenuItem(res.id);
          } else {
            setMenuAnchor(false);
            setToastErr(true);
          }
        });
      }
      if (menu?.id) {
        await addingMenuItem(menu.id);
      }
    }
    setLoading(false);
  };

  const addingMenuItem = async (menuId) => {
    let payload = {
      entity_type: formData.entity_type,
      entity_id: formData.entity_type === "none" ? null : formData.entity_id,
      name: formData.name,
      link: formData.link,
      open_new_page: formData.open_new_page,
      parent_id: selParentMenuItem ? selParentMenuItem.id : 0,
    };
    if (selParentMenuItem) {
      await dispatch(
        addSubMenuItem(payload, menuId, selParentMenuItem.id)
      ).then((res) => {
        setMenuAnchor(false);
        if (res) {
          setToastSucc(true);
          setToastSuccMsg("Item Added Successfully");
        } else {
          setToastErr(true);
        }
      });
    } else {
      await dispatch(addMenuItem(payload, menuId)).then((res) => {
        setMenuAnchor(false);
        if (res) {
          setToastSucc(true);
          setToastSuccMsg("Item Added Successfully");
        } else {
          setToastErr(true);
        }
      });
    }
  };

  const editingMenuItem = async (menuId) => {
    let payload = {
      ...editItemRecord.record,
      entity_type: formData.entity_type,
      entity_id: formData.entity_type === "none" ? null : formData.entity_id,
      name: formData.name,
      link: formData.link,
      open_new_page: formData.open_new_page,
    };
    if (editItemRecord.parentMenuItem) {
      await dispatch(
        editSubMenuItem(payload, menuId, editItemRecord.parentMenuItem.id)
      ).then((res) => {
        setMenuAnchor(false);
        if (res) {
          setToastSucc(true);
          setToastSuccMsg("Item Updated Successfully");
        } else {
          setToastErr(true);
        }
      });
    } else {
      await dispatch(editMenuItem(payload, menuId)).then((res) => {
        setMenuAnchor(false);
        if (res) {
          setToastSucc(true);
          setToastSuccMsg("Item Updated Successfully");
        } else {
          setToastErr(true);
        }
      });
    }
  };
  const hasChanges = JSON.stringify(formData) !== JSON.stringify(initialState);

  return (
    <>
      <Grid
        container
        style={{ position: "relative", borderTop: "1px solid #e1e1e1" }}
      >
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Controls.Select
            name="entity_type"
            label="Item Type"
            value={formData.entity_type}
            onChange={(e) => {
              setFormData({ ...initState, entity_type: e.target.value });
            }}
            placeholder="Select"
            options={menuItemOptions}
            labelFontWeight={400}
            height={36}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          />
          <hr className={classes.horizontalLine} />
        </Grid>
        {formData.entity_type === "CATEGORY" && (
          <Grid item xs={12}>
            <Controls.Select
              name="cat_entity"
              label="Category"
              value={formData.entity_id}
              onChange={(e) => {
                onFormFieldChange("cat_entity", e.target.value);
              }}
              placeholder="Select"
              options={categoryOptions}
              labelFontWeight={400}
              height={36}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              indent
            />
            <hr className={classes.horizontalLine} />
          </Grid>
        )}
        {formData.entity_type === "COLLECTION" && (
          <Grid item xs={12}>
            <Controls.Select
              name="coll_entity"
              label="Collection"
              value={formData.entity_id}
              onChange={(e) => {
                onFormFieldChange("coll_entity", e.target.value);
              }}
              placeholder="Select"
              options={collectionOptions}
              labelFontWeight={400}
              height={36}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            />
            <hr className={classes.horizontalLine} />
          </Grid>
        )}
        {formData.entity_type === "PAGE" && (
          <Grid item xs={12}>
            <Controls.Select
              name="page_entity"
              label="Select Page"
              value={formData.entity_id}
              onChange={(e) => {
                onFormFieldChange("page_entity", e.target.value);
              }}
              placeholder="Select"
              options={pageOptions}
              labelFontWeight={400}
              height={36}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            />
            <hr className={classes.horizontalLine} />
          </Grid>
        )}
        {(formData.entity_id !== "none" ||
          formData.entity_type === "CUSTOM") && (
          <Grid item xs={12}>
            <Controls.Input
              name="name"
              label="Name"
              value={formData.name}
              textClass={classes.input}
              labelFontWeight={400}
              onChange={(e) => {
                onFormFieldChange("name", e.target.value);
              }}
            />
            <hr className={classes.horizontalLine} />
          </Grid>
        )}
        {formData.entity_type === "CUSTOM" && (
          <Grid item xs={12}>
            <Controls.Input
              name="link"
              label="Link"
              value={formData.link}
              labelFontWeight={400}
              textClass={classes.input}
              onChange={(e) => {
                onFormFieldChange("link", e.target.value);
              }}
            />
            <Controls.Checkbox
              style={{ marginBottom: 18, marginLeft: 105 }}
              name="open_new_page"
              label={
                <Typography style={{ fontSize: 14 }}>
                  Open in a new tab
                </Typography>
              }
              checkboxStyles={{ padding: 0, marginLeft: 11, marginRight: 12 }}
              value={formData.open_new_page}
              onChange={(e) => {
                onFormFieldChange("open_new_page", e.target.checked);
              }}
              color="secondary"
            />
            <hr className={classes.horizontalLine} />
          </Grid>
        )}
      </Grid>
      <div
        style={{
          marginTop: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          paddingBottom: "28px",
          gap: "16px",
        }}
      >
        <OutlinedButton
          style={{ height: 36, minWidth: "4rem", padding: "16px 0px" }}
          onClick={() => setMenuAnchor(false)}
        >
          Cancel
        </OutlinedButton>
        <SolidButton
          loading={loading}
          onClick={onHandleSubmit}
          style={{ height: 36, minWidth: "4rem", padding: "16px 10px" }}
          disabled={disabled || !hasChanges}
        >
          Save
        </SolidButton>
      </div>
    </>
  );
};

export default MenuDrawer;

import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import FormModal from "../../../../components/common/FormModal";
import Input from "../../../../components/controls/Input";
import SolidButton from "../../../../components/buttons/SolidButton";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import Toast from "../../../../components/Layout/Toast";
import { getStoreIdFromUrl } from "../../../../utils/storeUtil";
import { generateReport } from "../../../../redux/reports/reportActions";
import { useDispatch } from "react-redux";

const ExportCta = () => {
  const dispatch = useDispatch();
  const initialData = {
    target_date: "",
    report_type: "CONTRACT",
    format: "CSV",
  };
  const storeId = getStoreIdFromUrl();

  const [openExport, setOpenExport] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [openToast, setOpenToast] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClickExport = () => {
    setOpenExport(true);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onBlur = (e) => {
    const { name, value } = e.target;
    let error = "";
    if (name === "target_date" && value.trim() === "") {
      error = "Target date is required.";
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleExport = async () => {
    let hasError = false;
    const newErrors = {};
    if (!formData.target_date.trim()) {
      newErrors.target_date = "Target date is required.";
      hasError = true;
    }
    setErrors(newErrors);
    if (hasError) return;

    const data = {
      report_generator_type: formData.report_type,
      report_type: formData.report_type,
      type: formData.report_type,
      format: formData.format,
      report_columns: [],
      target_date: formData.target_date,
    };

    try {
      setLoading(true);
      const response = await dispatch(generateReport(data));
      setLoading(false);
      if (response?.payload?.status === "Pending") {
        setOpenToast(true);
        setOpenExport(false);
        setFormData(initialData);
        setErrors({});
      }
    } catch (error) {
      console.error("Error while generating report:", error);
    }
  };

  return (
    <>
      <OutlinedButton onClick={onClickExport}>Export</OutlinedButton>
      <FormModal
        open={openExport}
        onClose={() => {
          setOpenExport(false);
          setFormData(initialData);
          setErrors({});
        }}
        heading="Upcoming Contracts Report"
        width={370}
        maxHeight={65}
        hideBottomStickyButton={true}
      >
        <div style={{ borderTop: "1px solid #e1e1e1", paddingTop: "14px" }}>
          <div>
            <Typography style={{ fontSize: "14px", marginBottom: "15px" }}>
              Select a date to download a report of all contracts due on target
              date.
            </Typography>
            <Input
              type="date"
              name="target_date"
              label="Target date"
              value={formData.target_date}
              onChange={onChange}
              error={errors.target_date}
              onBlur={onBlur}
              labelFontWeight={400}
              labelPlacement="top"
              inputProps={{ maxLength: 30 }}
            />

            <div style={{ margin: "8px 0px 20px 0px", textAlign: "end" }}>
              <SolidButton loading={loading} onClick={handleExport}>
                Generate Report
              </SolidButton>
            </div>
          </div>
        </div>
      </FormModal>
      <Toast
        open={openToast}
        close={() => setOpenToast(false)}
        severity="success"
        message={
          <React.Fragment>
            Contract Report generation is scheduled. To download, please visit{" "}
            <a href={`${storeId}/reports`} rel="noopener noreferrer">
              Reports{" "}
            </a>
            section
          </React.Fragment>
        }
      />
    </>
  );
};

export default ExportCta;

import * as piexif from "piexifjs";

// Step 1: Read metadata from the source image
function getImageMetadata(imageSrc) {
  return new Promise((resolve, reject) => {
    fetch(imageSrc)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const binaryStr = new Uint8Array(buffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        );
        const metadata = piexif.load(binaryStr);
        resolve(metadata);
      })
      .catch(reject);
  });
}

// Step 2: Add metadata to the new image
export async function addMetadataToCroppedImage(
  croppedImageSrc,
  originalImageSrc
) {
  try {
    const metadata = await getImageMetadata(originalImageSrc);
    const dataUrl = croppedImageSrc;
    const binaryStr = atob(dataUrl.split(",")[1]); // Extract base64 data
    const buffer = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
      buffer[i] = binaryStr.charCodeAt(i);
    }

    const newDataUrl = piexif.insert(piexif.dump(metadata), dataUrl);
    return newDataUrl;
  } catch (error) {
    console.error("Failed to add metadata:", error);
    return croppedImageSrc; // Fallback to the original cropped image
  }
}

import React from "react";
export function Categories({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <mask id="path-2-inside-1_27415_34425" fill="white">
        <rect x="3" y="3" width="12" height="8" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="3"
        width="12"
        height="8"
        rx="0.5"
        stroke="#CCCCCC"
        stroke-width="2"
        mask="url(#path-2-inside-1_27415_34425)"
      />
      <mask id="path-3-inside-2_27415_34425" fill="white">
        <rect x="3" y="12" width="3.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="12"
        width="3.1"
        height="3.5"
        rx="0.5"
        fill="#CCCCCC"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-3-inside-2_27415_34425)"
      />
      <mask id="path-4-inside-3_27415_34425" fill="white">
        <rect x="7" y="12" width="4.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="7"
        y="12"
        width="4.1"
        height="3.5"
        rx="0.5"
        fill="#CCCCCC"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-4-inside-3_27415_34425)"
      />
      <mask id="path-5-inside-4_27415_34425" fill="white">
        <rect x="12" y="12" width="3.1" height="3.5" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="12"
        width="3.1"
        height="3.5"
        rx="0.5"
        fill="#CCCCCC"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-5-inside-4_27415_34425)"
      />
    </svg>
  );
}

export function SelCategories({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <rect x="3" y="3" width="12" height="8" rx="0.5" fill="#CCCCCC" />
      <rect x="3" y="12" width="3.1" height="3.5" rx="0.5" fill="#1641DB" />
      <rect x="7" y="12" width="4.1" height="3.5" rx="0.5" fill="#1641DB" />
      <rect x="12" y="12" width="3.1" height="3.5" rx="0.5" fill="#1641DB" />
    </svg>
  );
}

import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  grid: {
    marginLeft: 100,
    marginRight: 156,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  productHeading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
  },
  stockBtn: {
    outline: "none",
    background: "#1641DB",
    color: "white",
    fontWeight: 600,
    lineHeight: "24px",
    borderRadius: 0,
    fontSize: 14,
    boxShadow: "none",
    textTransform: "none",
    // boxShadow:
    //   "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      background: "#1641DB",
    },
    "&:first-child": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&:last-child": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  managebtn: {
    border: "1px solid #1641DB",
    color: "#1641DB",
    background: "white",
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 16,
    padding: 7,
    width: 171,
    marginRight: 24,
    textTransform: "none",
    boxShadow: "none",
    height: 48,
    "&:hover": {
      background: "white",
    },
  },
  bulkbtn: {
    background: "#DFE4F5",
    border: "1px solid #BEC7EB",
    color: "#1641DB",
    borderRadius: 6,
    height: 48,
    fontWeight: 600,
    marginRight: 20,
    lineHeight: "24px",
    fontSize: 16,
    padding: 16,
    textTransform: "none",
    width: 185,
    boxShadow: "none !important",
    "&:hover": {
      background: "#DFE4F5",
    },
  },
  endicon: {
    marginLeft: 12,
  },
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    flexGrow: 1,
    maxWidth: 410,
    minWidth: 175,
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    marginLeft: "6px",
    border: "none",
    fontSize: "12px",
    flex: 1,
    minWidth: 0,
    marginTop: "3px",
  },
  button: {
    borderRadius: "6px",
    border: "1px solid #1641DB",
    backgroundColor: "rgba(255,255,255,0.1)",
    marginLeft: "24px",
    color: "#1641DB",
    boxShadow: "none",
    height: "40px",
    width: "100px",
    padding: "6px 8px",
  },
  disabledButton: {
    background: "rgb(196, 196, 196) !important",
  },
  buttonlabel: {
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "none",
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  delBtn: {
    background: "#ED0000",
    color: "white",
    borderRadius: 6,
    height: 48,
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: 16,
    padding: 16,
    textTransform: "none",
    width: 163,
    "&:hover": {
      background: "#ED0000",
    },
  },
  modal: {
    width: "570px",
    marginLeft: "auto",
    top: "0px",
    marginRight: "0px",
    zIndex: 3,
  },
  row: {
    "& td": {
      fontWeight: 600,
      paddingLeft: theme.spacing(2.75),
      fontSize: 12,
      lineHeight: "16px",
      color: "#666666",
    },
  },
  prodimage: {
    height: "40px",
    width: "40px",
    borderRadius: "6px",
    objectFit: "contain",
  },
  anchor: {
    "& li": {
      color: "#1641DB",
      fontSize: 12,
      lineHeight: "16px",
    },
  },
  input: {
    textAlign: "center",
    fontSize: "14px",
  },
  stockInputRoot: {
    "& fieldset": {
      borderWidth: "1px !important",
    },
    "&:focus fieldset": {
      borderWidth: "1px !important",
    },
  },
  bulkContainer: {
    background: "#E5E5E5",
    borderRadius: 6,
    padding: 28,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
    cursor: "pointer",
  },
  uploadContainer: {
    background: "#FFFFFF",
    border: "1px solid #E5E5E5",
    borderRadius: 6,
    padding: "61px 58px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
    minHeight: 209,
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  selector: {
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    fontSize: 12,
    padding: 12,
    height: 44,
    textTransform: "none",
    minWidth: 142,
    justifyContent: "space-between",
  },
  catSheet: {
    position: "absolute",
    top: 50,
    display: "flex",
    borderRadius: 6,
    background: "#FFFFFF",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
  },
  label: {
    fontWeight: 400,
    fontSize: 12,
    width: "70%",
  },
  clearBtn: {
    outline: "none",
    fontWeight: 400,
    fontSize: 12,
    padding: 12,
    marginLeft: -2,
    textTransform: "none",
    color: "#999999",
    height: 42,
    borderRadius: 6,
    whiteSpace: "noWrap",
    textOverflow: "visible",
  },
  bulkActionBtn: {
    background: "transparent",
    textTransform: "none",
    fontSize: "12",
    fontWeight: 400,
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  hover: {
    "&:hover": {
      background: "rgba(220, 220, 220, 0.2)",
    },
  },
  errorDiv: {
    background: "#FF6B6B",
    color: "#FFFFFF",
    border: "1px solid #FF6B6B",
    padding: 18,
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  downloadBtn: {
    background: "#FFFFFF1A",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    color: "#FFFFFF",
    padding: "6px 18px",
    textTransform: "none",
    borderRadius: 6,
    fontSize: 12,
    "&:hover": {
      background: "#FFFFFF1F",
    },
  },
  applyBtn: {
    width: "100%",
    backgroundColor: "#1641DB",
    textTransform: "none",
    color: "white",
    fontSize: "12px",
    lineHeight: "16px",
    "&:hover": {
      backgroundColor: "#1641DB",
    },
  },
  dividerBox: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 20,
    paddingTop: 20,
    borderBottom: "1px solid #E6E6E6",
  },
  heading: {
    fontSize: 14,
    fontWeight: 600,
  },
  subTxt: {
    fontSize: 13,
    fontWeight: 400,
    color: "#666666",
    marginTop: 4,
    paddingRight: 10,
  },
  bulkAction: {
    fontWeight: 400,
    fontSize: 12,
    borderRadius: 6,
    padding: 9,
    cursor: "pointer",
    "&:hover": {
      background: "#F5F7FC",
    },
    display: "flex",
    alignItems: "center",
  },
  noPicIcon: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 40,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerBorder: {
    paddingBottom: 10,
    borderBottom: "1px solid #E1E1E1",
  },
  productName: {
    fontWeight: 400,
    fontSize: 14,
  },
  productCount: {
    fontSize: 14,
    fontWeight: 600,
    margin: "24px 0 16px 0",
  },
  auto_Generated_text: {
    fontSize: 12,
    color: "#1641DB",
    width: "22%",
    margin: "0px 12px",
    padding: "3px 5px",
    borderRadius: 4,
    background: "#E9EEFF",
  },
  hideAutoGenerated: {
    position: "fixed",
    bottom: 0,
    background: "#fff",
    height: "60px",
    width: "60%",
    display: "flex",
    alignItems: "center",
    zIndex: 2,
    gap: 10,
  },
  card: {
    marginBottom: "10px",
    boxShadow: "none",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    padding: "16px",
    gap: "20px",
  },
  dragIndicator: {
    color: "#E1E1E1",
    "&:hover": {
      color: "#666666",
    },
  },
}));

import React from "react";
export function MenuBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_27415_34336"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34336)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#CCCCCC" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <mask id="path-4-inside-1_27415_34336" fill="white">
        <rect x="2.69983" y="4.55005" width="12.6" height="4.7" rx="0.5" />
      </mask>
      <rect
        x="2.69983"
        y="4.55005"
        width="12.6"
        height="4.7"
        rx="0.5"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-4-inside-1_27415_34336)"
      />
      <path
        d="M3.19983 10.8L14.7998 10.8V14.8H3.19983L3.19983 10.8Z"
        stroke="#CCCCCC"
      />
    </svg>
  );
}

export function SelMenuBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_27444_247"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27444_247)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#CCCCCC" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <rect
        x="2.69983"
        y="4.55005"
        width="12.6"
        height="4.7"
        rx="0.5"
        fill="#1641DB"
      />
      <path
        d="M2.69983 10.8C2.69983 10.5239 2.92369 10.3 3.19983 10.3H14.7998C15.076 10.3 15.2998 10.5239 15.2998 10.8V14.8C15.2998 15.0762 15.076 15.3 14.7998 15.3H3.19983C2.92369 15.3 2.69983 15.0762 2.69983 14.8V10.8Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}

import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/Layout/PageLoader";

export default function DisplaySlug() {
  const { displaySlug } = useParams();
  const Component = React.lazy(() => import(`./pages/${displaySlug}`));

  return (
    <Suspense fallback={<PageLoader />}>
      <div style={{ maxWidth: 815 }}>
        <Component />
      </div>
    </Suspense>
  );
}

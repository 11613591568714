import React from "react";

const CheckoutIcon = ({
  viewBox = "0 0 56 56",
  stroke = "#999999",
  fill = "#999999",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <mask
        id="mask0_27327_36101"
        style={{ maskType: "alpha" }} // Fixed the style prop
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="28"
        height="28"
      >
        <rect width="28" height="28" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27327_36101)">
        <path
          d="M8.52587 24.6347C8.03373 24.6347 7.61898 24.4661 7.28162 24.1287C6.94445 23.7913 6.77587 23.3767 6.77587 22.8847C6.77587 22.3926 6.94445 21.9779 7.28162 21.6405C7.61898 21.3033 8.03373 21.1347 8.52587 21.1347C9.01801 21.1347 9.43266 21.3033 9.76983 21.6405C10.1072 21.9779 10.2759 22.3926 10.2759 22.8847C10.2759 23.3767 10.1072 23.7913 9.76983 24.1287C9.43266 24.4661 9.01801 24.6347 8.52587 24.6347ZM19.4745 24.6347C18.9823 24.6347 18.5677 24.4661 18.2305 24.1287C17.8931 23.7913 17.7245 23.3767 17.7245 22.8847C17.7245 22.3926 17.8931 21.9779 18.2305 21.6405C18.5677 21.3033 18.9823 21.1347 19.4745 21.1347C19.9666 21.1347 20.3813 21.3033 20.7187 21.6405C21.0559 21.9779 21.2245 22.3926 21.2245 22.8847C21.2245 23.3767 21.0559 23.7913 20.7187 24.1287C20.3813 24.4661 19.9666 24.6347 19.4745 24.6347ZM6.86104 6.41666L9.97516 12.9681H17.7154C17.8502 12.9681 17.9698 12.9343 18.0745 12.8669C18.1793 12.7996 18.269 12.7062 18.3437 12.5866L21.3951 7.04491C21.4847 6.88041 21.4922 6.73457 21.4175 6.60741C21.3427 6.48024 21.2155 6.41666 21.036 6.41666H6.86104ZM6.29112 5.24999H21.4802C21.9874 5.24999 22.3688 5.45678 22.6245 5.87037C22.8803 6.28395 22.8939 6.70988 22.665 7.14816L19.331 13.2236C19.162 13.5079 18.9425 13.7308 18.6724 13.8924C18.4025 14.0539 18.106 14.1347 17.7828 14.1347H9.45016L8.03208 16.7373C7.9125 16.9168 7.9088 17.1112 8.021 17.3206C8.13319 17.53 8.30148 17.6347 8.52587 17.6347H21.2245V18.8014H8.52587C7.84531 18.8014 7.33752 18.5146 7.0025 17.941C6.66747 17.3674 6.6592 16.7911 6.9777 16.2123L8.73208 13.0847L4.44254 4.08332H2.3335V2.91666H5.18279L6.29112 5.24999Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CheckoutIcon;

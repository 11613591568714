// Core react imports
import React, { useEffect, useState, Fragment } from "react";
// Routing imports
import { useHistory, Link } from "react-router-dom";
// Ui lib imports
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Typography,
} from "@material-ui/core";
// Icon imports
import CreateCoupon from "../../../icons/CreateCoupon";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "../../../icons/DeleteIcon";
// Style imports
import { useStyles } from "../styles";
// Custom component imports
import Paginator from "../../../components/Paginator/Paginator";
import ShareModal from "./ShareModal";
import ConfirmModal from "../../../components/common/ConfirmModal";
import Toast from "../../../components/Layout/Toast";
import Controls from "../../../components/controls/Controls";
// Import redux related stuff
import {
  clearCoupons,
  deleteCoupon,
  fetchCoupons,
  toggleCouponActivation,
  clearCoupon,
  setCouponsPerPage,
} from "../../../redux/coupons/couponActions";
import { CREATE_COUPON_LOADING } from "../../../redux/coupons/couponTypes";
import { useDispatch, useSelector } from "react-redux";
// Constant imports
import {
  COUPONS_KEY,
  COUPONS_PER_PAGE,
  DEFAULT_COUPONS_PER_PAGE,
} from "../../../utils/utils";
import { getStoreIdFromUrl } from "../../../utils/storeUtil";
import {
  layoutBodyBox,
  layoutContainer,
  layoutSubHeader,
} from "../../../styles/common";
import SolidButton from "../../../components/buttons/SolidButton";
import PageLoader from "../../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../../components/common/HeaderBreadcrumbs";
import NoCouponImage from "../../../components/Coupon/NoCouponImage";
import NoRecord from "../../../components/common/NoRecord";
// Exporting default functional component
export default function CouponsList() {
  // using third party/custom hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  // State extraction and local state definitions
  const couponState = useSelector((state) => state.coupon);
  const [isDeleting, setIsDeleting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPageSoFar, setMaxPageSoFar] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [inputKeyword, setInputKeyword] = useState("");
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [couponToShare, setCouponToShare] = useState(null);
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const storeId = getStoreIdFromUrl();
  // Pagination related calcs
  const couponsPerPage = couponState.couponsPerPage;
  let recordsPerPage = currentPage * couponsPerPage;
  const numberOfPages = couponState.numberOfPages;

  // Effects
  useEffect(() => {
    if (!couponsPerPage) {
      dispatch(setCouponsPerPage(COUPONS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [couponsPerPage]);

  useEffect(() => {
    if (!couponState.coupons.loaded) {
      dispatch(fetchCoupons(currentPage, couponsPerPage));
    }
    // eslint-disable-next-line
  }, [storeId]);

  // Handler methods
  const onChangeCouponsPerPage = (updatedCouponsPerPage) => {
    localStorage.setItem(COUPONS_KEY, updatedCouponsPerPage);
    dispatch(clearCoupons());
    setCurrentPage(0);
    setMaxPageSoFar(0);
    dispatch(setCouponsPerPage(updatedCouponsPerPage));
    dispatch(fetchCoupons(currentPage, updatedCouponsPerPage));
  };

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(fetchCoupons(currentPage + 1, couponsPerPage));
      setMaxPageSoFar(
        maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
      );
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      setMaxPageSoFar(0);
      setCurrentPage(0);
      dispatch(fetchCoupons(0, couponsPerPage, keyword));
    }
  };

  if (couponState.error) {
    setShowErrorMessage(true);
    dispatch(clearCoupon());
  }

  if (couponState.toggleSuccess) {
    setShowSuccessMessage(true);
    dispatch(clearCoupon());
  }
  if (couponState.createSuccess || couponState.updateSuccess) {
    setShowSuccessMessage(couponState.message);
    dispatch({ type: CREATE_COUPON_LOADING });
  }

  if (couponState.deleteSuccess) {
    dispatch(fetchCoupons(currentPage, couponsPerPage));
    setShowSuccessMessage(true);
    dispatch(clearCoupon());
    if (couponState.numberOfPages === maxPageSoFar) {
      setCurrentPage(couponState.numberOfPages - 1);
      setMaxPageSoFar(couponState.numberOfPages - 1);
    } else {
      setCurrentPage(currentPage);
      setMaxPageSoFar(currentPage);
    }
  }

  return (
    <>
      <div className={layoutContainer}>
        <div className={layoutSubHeader}>
          <HeaderBreadcrumbs list={[{ label: "Coupons" }]} />
          <SolidButton
            endIcon={<CreateCoupon />}
            onClick={() => history.push("./coupons/create")}
            style={{ width: 185 }}
          >
            Create Coupon
          </SolidButton>
        </div>
        {!couponState.coupons.length && !searchKeyword ? (
          <Box
            display="flex"
            justifyContent="center"
            style={{ marginTop: "40px" }}
          >
            {couponState.loading ? (
              <PageLoader />
            ) : (
              <NoRecord
                text="Start Creating Coupons for your customers"
                arrow={true}
                image={<NoCouponImage />}
              />
            )}
          </Box>
        ) : (
          <div style={{ position: "relative", width: "100%" }}>
            <div className={layoutBodyBox}>
              <div className={classes.tableHeader}>
                <div className={classes.search}>
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search coupon by code"
                    className={classes.searchBox}
                    value={inputKeyword}
                    onChange={(e) => {
                      setInputKeyword(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (inputKeyword.trim() !== "") {
                          setSearchKeyword(inputKeyword);
                          onSearch(inputKeyword.trim());
                        }
                      }
                      if (e.key === "Escape") {
                        setWhiteSpaceDiv(true);
                        setSearchKeyword("");
                        setInputKeyword("");
                        setMaxPageSoFar(0);
                        setCurrentPage(0);
                        dispatch(fetchCoupons(currentPage, couponsPerPage));
                      }
                    }}
                  />
                  {searchKeyword !== "" ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setWhiteSpaceDiv(true);
                        setSearchKeyword("");
                        setInputKeyword("");
                        setMaxPageSoFar(0);
                        setCurrentPage(0);
                        dispatch(fetchCoupons(currentPage, couponsPerPage));
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <TableContainer>
                <Table>
                  <TableHead className={classes.tablehead}>
                    <TableRow className={classes.headrow}>
                      <TableCell style={{ width: "10%" }}>Code</TableCell>
                      <TableCell style={{ width: "10%", textAlign: "right" }}>
                        Value
                      </TableCell>
                      <TableCell
                        style={{
                          width: "11%",
                          textAlign: "right",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Max Discount
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                          textAlign: "right",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Order Amount
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                          textAlign: "right",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Per Customer
                      </TableCell>
                      <TableCell
                        style={{
                          width: "15%",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Source
                      </TableCell>
                      <TableCell
                        style={{
                          width: "12%",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Auto Apply
                      </TableCell>
                      <TableCell style={{ width: "10%", textAlign: "center" }}>
                        {" "}
                        Expiry
                      </TableCell>
                      <TableCell style={{ width: "7%", textAlign: "center" }}>
                        Active
                      </TableCell>
                      <TableCell style={{ width: "5%" }}>Share</TableCell>
                      <TableCell style={{ width: "5%" }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tablebody}>
                    {couponState.coupons
                      .slice(
                        recordsPerPage,
                        recordsPerPage + couponsPerPage <
                          couponState.coupons.length
                          ? recordsPerPage + couponsPerPage
                          : couponState.coupons.length
                      )
                      .map((tableitem, index) => (
                        <TableRow
                          key={tableitem.id}
                          style={{
                            borderBottom:
                              (index + 1) % couponsPerPage === 0
                                ? "none"
                                : "auto",
                          }}
                        >
                          <TableCell style={{ width: "10%" }}>
                            <Link
                              to={`coupons/edit?id=${tableitem.id}`}
                              style={{
                                color: tableitem.active ? "#1641DB" : "#999999",
                                textDecoration: "none",
                              }}
                            >
                              {tableitem.code}
                            </Link>
                          </TableCell>
                          <TableCell
                            style={{ width: "10%", textAlign: "right" }}
                          >
                            {tableitem.type === "PERCENTAGE" ? (
                              <span
                                className={
                                  tableitem.active
                                    ? classes.blackText
                                    : classes.greyText
                                }
                              >
                                {tableitem.percentage}%
                              </span>
                            ) : (
                              <span
                                className={
                                  tableitem.active
                                    ? classes.blackText
                                    : classes.greyText
                                }
                              >
                                &#8377;{tableitem.max_discount}
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "13%",
                              textAlign: "right",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {tableitem.type === "PERCENTAGE" ? (
                              <span
                                className={
                                  tableitem.active
                                    ? classes.blackText
                                    : classes.greyText
                                }
                              >
                                {tableitem.max_discount === 0
                                  ? "--"
                                  : `₹${tableitem.max_discount}`}
                              </span>
                            ) : (
                              <span
                                className={
                                  tableitem.active
                                    ? classes.blackText
                                    : classes.greyText
                                }
                              >
                                --
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            style={{ width: "10%", textAlign: "right" }}
                          >
                            <span
                              className={
                                tableitem.active
                                  ? classes.blackText
                                  : classes.greyText
                              }
                            >
                              &#8377;{tableitem.min_sale_price}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{ width: "10%", textAlign: "right" }}
                          >
                            <span
                              className={
                                tableitem.active
                                  ? classes.blackText
                                  : classes.greyText
                              }
                            >
                              {tableitem.usage_per_customer}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{ width: "15%", textAlign: "center" }}
                          >
                            <span
                              className={
                                tableitem.active
                                  ? classes.blackText
                                  : classes.greyText
                              }
                            >
                              {tableitem.source &&
                              (tableitem.source === "SYS_REFERRAL" ||
                                tableitem.source === "SYS_REFEREE")
                                ? "Refer & Earn"
                                : "--"}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{ width: "10%", textAlign: "center" }}
                          >
                            <span
                              className={
                                tableitem.active
                                  ? classes.blackText
                                  : classes.greyText
                              }
                            >
                              {tableitem.auto_apply ? "YES" : "NO"}
                            </span>
                          </TableCell>
                          <TableCell
                            style={{ width: "10%", textAlign: "center" }}
                          >
                            <span
                              className={
                                tableitem.active
                                  ? classes.blackText
                                  : classes.greyText
                              }
                            >
                              {tableitem.validity
                                ? new Date(
                                    tableitem.validity
                                  ).toLocaleDateString()
                                : "No Expiry"}
                            </span>
                          </TableCell>
                          <TableCell style={{ width: "7%" }}>
                            <Controls.Switch
                              value={tableitem.active}
                              onChange={() =>
                                dispatch(
                                  toggleCouponActivation(
                                    tableitem.id,
                                    !tableitem.active,
                                    couponsPerPage
                                  )
                                )
                              }
                              style={{ marginBottom: 0 }}
                            />
                          </TableCell>
                          <TableCell
                            style={{ width: "5%", textAlign: "center" }}
                          >
                            <ShareIcon
                              onClick={() => {
                                if (tableitem.active) {
                                  setCouponToShare(tableitem);
                                  setIsOpenShareModal(true);
                                }
                              }}
                              color="secondary"
                              style={
                                tableitem.active
                                  ? {
                                      cursor: "pointer",
                                    }
                                  : { cursor: "", color: "#666" }
                              }
                              fontSize="small"
                            />
                          </TableCell>
                          <TableCell
                            style={{ width: "5%", textAlign: "center" }}
                          >
                            <span
                              onClick={() => {
                                setWhiteSpaceDiv(true);
                                setAnchorEl(tableitem);
                                setIsDeleting(true);
                                // dispatch(deleteCoupon(tableitem.id));
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <DeleteIcon />
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                {couponState.loading && (
                  <Fragment>
                    {!whiteSpaceDiv && (
                      <div
                        style={{
                          height: 450.4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      ></div>
                    )}
                    <LinearProgress color="secondary" />
                  </Fragment>
                )}

                {couponState.coupons.length === 0 && (
                  <div
                    style={{
                      height: COUPONS_PER_PAGE * 68 - 4,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginTop: -34,
                      }}
                    >
                      No Coupon Found
                    </Typography>
                  </div>
                )}
              </TableContainer>
            </div>
            <Paginator
              changeRecordsPerPage={onChangeCouponsPerPage}
              possibleRecordsPerPage={[DEFAULT_COUPONS_PER_PAGE, 20, 50, 100]}
              recordsPerPage={couponsPerPage}
              onNext={onNextPage}
              onPrev={onPrevPage}
              total={numberOfPages === 0 ? 1 : numberOfPages}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
      <ShareModal
        isOpen={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        coupon={couponToShare}
      />
      <ConfirmModal
        open={isDeleting}
        message={
          <span>
            Are you sure you want to delete coupon with code{" "}
            <b>{anchorEl?.code}</b> ?
          </span>
        }
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setIsDeleting(false);
          setAnchorEl(null);
        }}
        onConfirm={() => {
          dispatch(deleteCoupon(anchorEl.id));
          setAnchorEl(null);
          setIsDeleting(false);
        }}
      />
      <Toast
        open={showSuccessMessage || showSuccessMessage?.length > 0}
        close={() => setShowSuccessMessage(false)}
        message={couponState.message || showSuccessMessage}
        severity="success"
      />
      <Toast
        open={showErrorMessage}
        close={() => setShowErrorMessage(false)}
        message={couponState.message}
        severity="error"
      />
    </>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteCategory,
  fetchCategories,
  rearrangeCategories,
} from "../../redux/category/categoryActions";
import {
  Grid,
  Drawer,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import {
  layoutCenterContainer,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { fetchApps } from "../../redux/apps/appActions";
import AddVectorIcon from "../../icons/AddVectorIcon";
import Toast from "../../components/Layout/Toast";
import CategoryDrawer from "./components/CategoryDrawer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ContractIcon, ExpandIcon, NoCategoriesFigma } from "../../icons";
import ParentCategory from "./components/ParentCategory";
import SubCategory from "./components/SubCategory";
import ProductsDrawer from "./components/ProductsDrawer";
import DeleteModal from "../../components/common/DeleteModal";
import { showOrHideCategory } from "./components/AsyncUtils";
import { reorder } from "../../components/common/CommonUtils";
import { UPDATE_CATEGORY_LIST } from "../../redux/category/categoryTypes";
import NoRecord from "../../components/common/NoRecord";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 25,
  },
  layoutContainer: {
    width: "815px",
    padding: "28px",
    borderRadius: 10,
    backgroundColor: "#ffffff",
    boxShadow: "0px 8px 20px rgba(0,0,0,0.04)",
  },
  droppable: {
    position: "relative",
    width: "100%",
    marginTop: -15,
  },
  accordion: {
    width: "100%",
    height: "100%",
    padding: "10px",
    background: "white",
    borderRadius: "8px",
    border: "1px #E6E6E6 solid",
    marginTop: -25,
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.04)",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
  },
  addSubCategory: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px #E1E1E1 solid",
    paddingTop: 15,
    paddingLeft: 10,
    marginLeft: 20,
  },
  addSubCategoryText: {
    color: "#1641DB",
    fontSize: 14,
    fontWeight: "600",
    cursor: "pointer",
    marginRight: 20,
  },
});

const Categories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const apps = useSelector((state) => state.apps.apps);
  const { progress, categories } = useSelector((state) => state.categories);

  const [expanded, setExpanded] = useState(false);
  const [category, setCategory] = useState(null);
  const [editFlag, setEditFlag] = useState(false);
  const [parentCategory, setParentCategory] = useState("none");
  const [parentCategories, setParentCategories] = useState([]);
  const [categoryAnchor, setCategoryAnchor] = useState(false);
  const [productsAnchor, setProductsAnchor] = useState(false);
  const [deleteCategoryPopUp, setDeleteCategoryPopUp] = useState(false);

  const [isDeletingCategory, setIsDeletingCategory] = useState(false);
  const [categoryMap, setCategoryMap] = useState(new Map());
  const [childrenSlugs, setChildrenSlugs] = useState([]);

  const [success, setSuccess] = useState({
    value: false,
    message: "",
  });
  const [error, setError] = useState({
    value: false,
    message: "",
  });

  useEffect(() => {
    if (!apps.length) {
      dispatch(fetchApps());
    }
    // eslint-disable-next-line
  }, [apps.length]);

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchCategories());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCategoryMap(
      new Map(categories.map((category) => [category.id, category]))
    );

    setParentCategories(
      categories
        .filter((category) => category.level === 0)
        .map((item) => ({
          ...item,
          subcat: categories.filter(
            (scat) => scat.parent_cat_slug === item.slug
          ),
        }))
    );
  }, [categories]);

  const gmc = useMemo(() => {
    let shApps = apps.filter((app) => app.slug === "gmc");
    let shApp = {};
    if (shApps.length > 0) {
      shApp = shApps[0];
    }
    return shApp;
  }, [apps]);

  const handleViewProducts = (event) => {
    event.stopPropagation();
    let cat = categoryMap.get(+event.currentTarget.id);
    if (cat.level === 0) {
      let parentCat = parentCategories.find((pcat) => pcat.slug === cat.slug);
      if (parentCat && parentCat.subcat) {
        setChildrenSlugs(parentCat.subcat.map((scat) => scat.slug));
      }
    } else {
      setChildrenSlugs([]);
    }

    setCategory(categoryMap.get(+event.currentTarget.id));
    setProductsAnchor(true);
  };

  const closeProductsDrawer = (event) => {
    event.stopPropagation();
    setProductsAnchor(false);
    // setCategory(null);
  };

  const handleAddCategory = (event) => {
    setCategory(null);
    setParentCategory(
      event.target.id ? categoryMap.get(+event.target.id) : null
    );
    setCategoryAnchor(true);
  };

  const handleOnEdit = (event) => {
    event.stopPropagation();
    const tempCategory = categoryMap.get(+event.currentTarget.id);
    setCategory(tempCategory);
    setParentCategory(
      tempCategory.parent_cat_slug
        ? categories.find(
            (category) => category.slug === tempCategory.parent_cat_slug
          )
        : null
    );
    setEditFlag(true);
    setCategoryAnchor(true);
  };

  const closeCategoryDrawer = () => {
    setCategoryAnchor(false);
    setEditFlag(false);
    setCategory(null);
    setParentCategory(null);
  };

  const onSwitchChange = async (event) => {
    const { name: categoryId, checked: value } = event.target;

    const params =
      categoryMap.get(Number(categoryId)).level === 0
        ? "status"
        : "toggle-display";

    try {
      const response = await showOrHideCategory(categoryId, value, params);
      if (response.error) {
        setError({
          value: true,
          message: response.error,
        });
      } else {
        const updatedCategories = Array.isArray(response)
          ? response
          : [response];

        updatedCategories.forEach((category) =>
          categoryMap.set(Number(category.id), category)
        );

        dispatch({
          type: UPDATE_CATEGORY_LIST,
          payload: updatedCategories,
        });

        setSuccess({
          value: true,
          message: `${categoryMap.get(Number(categoryId)).name} is now ${
            value ? "visible on" : "hidden from"
          } the online store.`,
        });
      }
    } catch (error) {
      setError({
        value: true,
        message: error,
      });
    }
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();
    setCategory(categoryMap.get(+event.currentTarget.id));
    setDeleteCategoryPopUp(true);
  };

  const deleteConfirm = async (event) => {
    event.stopPropagation();

    setIsDeletingCategory(true);
    try {
      const result = await dispatch(deleteCategory(category.id));
      if (result && result.error) {
        setError({
          value: true,
          message: result.error,
        });
      } else {
        setSuccess({
          value: true,
          message: `${
            categoryMap.get(category.id).name
          } category deleted successfully`,
        });
        setDeleteCategoryPopUp(false);
        setCategory(null);
      }
    } catch (error) {
      setError({
        value: true,
        message: error.message,
      });
    }
    setIsDeletingCategory(false);
  };

  const leaveDelete = () => {
    setDeleteCategoryPopUp(false);
    setCategory(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    if (result.type === "PARENTCAT") {
      const { items, change } = reorder(
        parentCategories,
        result.source.index,
        result.destination.index,
        "slug"
      );

      setParentCategories(items);
      dispatch(rearrangeCategories(change));
    } else {
      let index = parentCategories.findIndex(
        (cat) => cat.slug === result.destination.droppableId
      );

      if (index >= 0) {
        const { items, change } = reorder(
          parentCategories[index].subcat,
          result.source.index,
          result.destination.index,
          "slug"
        );

        const newParentCategory = Array.from(parentCategories);
        newParentCategory[index].subcat = items;
        dispatch(rearrangeCategories(change));
        setParentCategories(newParentCategory);
      }
    }
  };

  const getItemStyle = (index, isDragging, draggableStyle) => {
    if (index !== 0) {
      return {
        userSelect: "none",
        marginTop: 40,
        padding: 0,
        ...draggableStyle,
      };
    } else {
      return {
        userSelect: "auto",
        marginTop: 24,
        // ...other styles
        ...draggableStyle, // If draggableStyle should be applied in this case as well
      };
    }
  };

  if (progress) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={layoutLoaderHeader}>
          <div className={layoutContainer}>
            <HeaderBreadcrumbs list={[{ label: "Category" }]} />
            <PageLoader />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <Grid
        container
        spacing={2}
        className={!categories.length ? layoutContainer : layoutCenterContainer}
      >
        <div style={{ width: !categories.length ? "100%" : 815 }}>
          <Grid item xs={12} className={layoutSubHeader}>
            <HeaderBreadcrumbs list={[{ label: "Category" }]} />
            <SolidButton
              startIcon={<AddVectorIcon />}
              onClick={handleAddCategory}
            >
              Add Category
            </SolidButton>
          </Grid>

          {!categories.length && !parentCategories.length ? (
            <NoRecord
              text="Start settting up your product categories"
              image={<NoCategoriesFigma />}
              arrow={true}
            />
          ) : (
            <div className={classes.container}>
              <div className={classes.layoutContainer}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="parentcat" type="PARENTCAT">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        className={classes.droppable}
                        ref={provided.innerRef}
                        style={{
                          backgroundColor: snapshot.isDraggingOver ? "" : "",
                        }}
                      >
                        {parentCategories.length > 0 &&
                          parentCategories.map((parentCategory, index) => {
                            return (
                              <Draggable
                                key={parentCategory.id}
                                draggableId={`${parentCategory.id}`}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    style={getItemStyle(
                                      index,
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Accordion className={classes.accordion}>
                                      <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        expandIcon={
                                          expanded ? (
                                            <ContractIcon />
                                          ) : (
                                            <ExpandIcon />
                                          )
                                        }
                                        onChange={() => {
                                          setExpanded(!expanded);
                                        }}
                                      >
                                        <ParentCategory
                                          category={parentCategory}
                                          categoryMap={categoryMap}
                                          handleProps={provided.dragHandleProps}
                                          handleViewProducts={
                                            handleViewProducts
                                          }
                                          onSwitchChange={onSwitchChange}
                                          handleOnEdit={handleOnEdit}
                                          handleOnDelete={handleOnDelete}
                                        />
                                      </AccordionSummary>
                                      <AccordionDetails
                                        className={classes.accordionDetails}
                                      >
                                        <Droppable
                                          droppableId={`${parentCategory.slug}`}
                                          type={`${parentCategory.slug}`}
                                        >
                                          {(provided, snapshot) => {
                                            return (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                {parentCategory.subcat.length >
                                                  0 &&
                                                  parentCategory.subcat.map(
                                                    (subCategory, index) => {
                                                      return (
                                                        <Draggable
                                                          key={`${subCategory.id}${index}`}
                                                          draggableId={`subcategory-${subCategory.id}${index}`}
                                                          index={index}
                                                        >
                                                          {(
                                                            provided,
                                                            snapshot
                                                          ) => (
                                                            <div
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              {...provided.draggableProps}
                                                            >
                                                              <SubCategory
                                                                parentCategory={
                                                                  parentCategory
                                                                }
                                                                subCategory={
                                                                  subCategory
                                                                }
                                                                categoryMap={
                                                                  categoryMap
                                                                }
                                                                handleProps={
                                                                  provided.dragHandleProps
                                                                }
                                                                handleViewProducts={
                                                                  handleViewProducts
                                                                }
                                                                onSwitchChange={
                                                                  onSwitchChange
                                                                }
                                                                handleOnEdit={
                                                                  handleOnEdit
                                                                }
                                                                handleOnDelete={
                                                                  handleOnDelete
                                                                }
                                                              />
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                {provided.placeholder}
                                              </div>
                                            );
                                          }}
                                        </Droppable>

                                        <div className={classes.addSubCategory}>
                                          <span
                                            id={parentCategory.id}
                                            className={
                                              classes.addSubCategoryText
                                            }
                                            onClick={handleAddCategory}
                                          >
                                            + Add Sub Category
                                          </span>
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          )}
        </div>
      </Grid>
      {deleteCategoryPopUp && (
        <DeleteModal
          item={category}
          isDeleting={isDeletingCategory}
          onConfirmDelete={deleteConfirm}
          onCancel={leaveDelete}
        />
      )}
      <Drawer
        open={categoryAnchor}
        onClick={(event) => event.stopPropagation()}
        anchor="right"
        PaperProps={{
          style: {
            paddingLeft: 28,
            paddingRight: 28,
            paddingBottom: 28,
            paddingTop: 15,
            width: 570,
          },
        }}
      >
        <CategoryDrawer
          gmc={gmc}
          edit={editFlag}
          category={category}
          parentCategory={parentCategory}
          parentCategories={parentCategories}
          closeCategoryDrawer={closeCategoryDrawer}
          setSuccess={setSuccess}
          setError={setError}
        />
      </Drawer>
      <Drawer
        open={productsAnchor}
        onClick={(event) => event.stopPropagation()}
        onClose={closeProductsDrawer}
        anchor="right"
        PaperProps={{
          style: {
            width: 570,
          },
        }}
      >
        <ProductsDrawer
          category={category}
          closeProductsDrawer={closeProductsDrawer}
          childrenSlugs={childrenSlugs}
          setSuccess={setSuccess}
          setError={setError}
        />
      </Drawer>
      {success.value && (
        <Toast
          open={success.value}
          message={success.message}
          close={() => setSuccess({ value: false, message: "" })}
        />
      )}
      {error.value && (
        <Toast
          open={error.value}
          message={error.message}
          close={() => setError({ value: false, message: "" })}
          severity="error"
        />
      )}
    </div>
  );
};

export default Categories;

import React from "react";
import { IconButton, Modal, Typography, makeStyles } from "@material-ui/core";
import CombinedButtons from "../buttons/CombinedButtons";
import { CloseIcon } from "../../icons";
import { rowFlexAlignStart } from "../../styles/common";
import { InfoOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  upperDiv: {
    padding: 25,
    borderRadius: 12,
    paddingBottom: 16,
    position: "absolute",
    background: "#ffffff",
    width: 500,
  },
  div: {
    height: "50px",
    position: "relative",
    marginTop: 50,
    marginBottom: 7,
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});

const DeleteModal = ({
  item,
  onCancel,
  onConfirmDelete,
  isDeleting,
  deleteMessage,
  style,
  page = "",
  heading = "",
}) => {
  const classes = useStyles();
  return (
    <Modal
      open={item ? true : false}
      onClose={onCancel}
      className={classes.modal}
    >
      <div className={classes.upperDiv}>
        {heading && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
              borderBottom: "1px solid #E0E0E0",
              paddingBottom: 9,
              marginTop: -8,
            }}
          >
            <p style={{ fontSize: 24, fontWeight: 600, marginBottom: 0 }}>
              {heading}
            </p>
            <IconButton aria-label="close" onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <p style={{ fontSize: 16 }}>
          Do you really want to delete <b> {item.name ? item.name : item}</b>{" "}
          {page}?
        </p>
        {deleteMessage && (
          <div className={rowFlexAlignStart} style={style}>
            <InfoOutlined style={{ color: "#FF6060", marginTop: "2px" }} />
            <Typography style={{ fontSize: 14, marginLeft: "8px" }}>
              {deleteMessage}
            </Typography>
          </div>
        )}
        <div className={classes.div}>
          <div className={classes.button}>
            <CombinedButtons
              loading={isDeleting}
              outlinedBtnAction={onCancel}
              solidBtnAction={onConfirmDelete}
              isDelete={true}
              solidBtnText="Delete"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;

import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { addProduct } from "../../../redux/product/productActions";
import ImageHandler from "../../../components/ImageHandler";
import { IconButton } from "@material-ui/core";
import Controls from "../../../components/controls/Controls";
import { uploadImages } from "../../../utils/imageUploader";
import { fetchAdditionalFields } from "../../../redux/additionalFields/additionalFieldsActions";
import Editor from "./Editor";
import SolidButton from "../../../components/buttons/SolidButton";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import ImageList from "../../../components/ImageList";
import DrawerCloseIcon from "../../../icons/DrawerCloseIcon";
import { Form } from "../../../components/form/useForm";
import { createProductFields, shouldRenderField } from "./ProductHelper";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenterCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexCenterEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  heading: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 10,
    marginTop: 20,
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    marginBottom: 10,
  },
  headerBorder: {
    paddingBottom: 10,
    borderBottom: "1px solid #E1E1E1",
  },
  editButton: {
    fontSize: "14px",
    fontWeight: 600,
  },
  editorHelperText: {
    color: "#1641DB",
    cursor: "pointer",
    fontSize: 12,
    marginTop: 6,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
  },
  images: {
    width: "60px",
    height: "60px",
    borderRadius: "6px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #1641DB",
    marginRight: "24px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 0,
  },
  rupee: {
    fontSize: 16,
    lineHeight: "16px",
    color: "#999999",
  },
  addfields: {
    width: "24px",
    height: "24px",
    border: "1px solid #1641DB",
    borderRadius: "6px",
    paddingLeft: "4px",
    marginLeft: "16px",
    marginTop: "5px",
  },
  labels: {
    fontSize: "14px",
    lineHeight: "20px",
    color: "1A1A1A",
    fontWeight: 600,
    fontFamily: "Noto Sans",
    paddingTop: "8px",
  },
  addIcon: {
    height: "14px",
    width: "14px",
    color: "#1641DB",
    cursor: "pointer",
  },
  loader: {
    height: "calc(100vh)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const VariantForm = ({
  onClose,
  onVariantSave,
  original,
  storeId,
  categories,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    fields: additionalFields,
    variantFields: { nonNativeFields },
  } = useSelector((state) => state.additionalFields);

  const initialState = {
    name: original.name,
    sale_price: original.sale_price,
    description: original.description,
    categories: original.categories,
    super_product_sku: original.super_product_sku,
    mrp: original.mrp,
    tax_group: original.tax_group,
    hsn_code: original.hsn_code,
    cat_name: original?.cat_name,
    cat_slug: original.cat_slug,
    measuring_unit: original.measuring_unit,
    brand: original.brand,
    show_online: original.show_online,
    pp_tax_included: original.pp_tax_included,
    sp_tax_included: original.sp_tax_included,
    product_id: original.product_id,
    video_url: original.video_url,
    extra_product_fields: [],
  };

  const errorState = {
    name: {
      msg: "Please enter Name",
      required: true,
      error: false,
    },
    sale_price: {
      msg: "Price",
      required: true,
      error: false,
    },
    mrp: {
      msg: "Mrp should be higher than Sale Price",
      required: false,
      error: false,
    },
  };

  const defaultCrop = { x: 0, y: 0, width: 250, height: 250 };

  const [cropImgs, setCropImgs] = useState(original?.images || []);
  const [filenames, setFilenames] = useState(original?.images || []);
  const [openImageHandler, setOpenImageHandler] = useState(false);
  const [openImageHandlerIndex, setOpenImageHandlerIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [product, setProduct] = useState(initialState);

  const showProductIdAndVideo = storeId === 351857;

  const [expandDesc, setExpandDesc] = useState(false);

  const [errors, setErrors] = useState(errorState);

  useEffect(() => {
    if (additionalFields.length === 0) {
      dispatch(fetchAdditionalFields());
    }
    // eslint-disable-next-line
  }, []);

  const changeHandler = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const validate = (event) => {
    setErrors({
      ...errors,
      [event.target.name]: {
        ...errors[event.target.name],
        error: event.target.value === "" ? true : false,
      },
    });
  };

  const onDescriptionChange = (desc) => {
    let event = { target: { name: "description", value: desc } };
    changeHandler(event);
  };

  const changeNonNative = (event) => {
    const { name, value } = event.target;

    setProduct((prevState) => ({
      ...prevState,
      extra_product_fields: {
        ...prevState.extra_product_fields,
        [name]: { value: value },
      },
    }));
  };

  const onImageRemove = (index) => {
    let newImages = cropImgs.filter((img, i) => {
      return index !== i;
    });
    let fnames = filenames.filter((fname, i) => {
      return index !== i;
    });
    setFilenames(fnames);
    setCropImgs(newImages);
  };

  const onImageClick = (index) => {
    setOpenImageHandler(true);
    setOpenImageHandlerIndex(index);
  };

  const onFocus = (event) => {
    setErrors({
      ...errors,
      [event.target.name]: {
        ...errors[event.target.name],
        error: false,
      },
    });
  };

  const formatFieldValue = (field) => {
    const fieldData = product.extra_product_fields[field.id.toString()];
    if (!fieldData?.value) return "";
    const outputValue = fieldData.value;
    const additionalData = fieldData.data ? `:${fieldData.data}` : "";
    return `${outputValue}${additionalData}`;
  };

  const creaetProductRequest = (product, images) => {
    let request = {
      super_product_sku: product.super_product_sku,
      name: product.name,
      display_name: product.name,
      description: product.description,
      sale_price: product.sale_price ? parseFloat(product.sale_price) : null,
      images: images,
      brand: product.brand,
      show_online: product.show_online,
      measuring_unit: product.measuring_unit,
      mrp: product.mrp ? parseFloat(product.mrp) : null,
      sp_tax_included: product.sp_tax_included,
      pp_tax_included: product.pp_tax_included,
      tax_group: product.tax_group,
      hsn_code: product.hsn_code,
      product_id: product.product_id,
      video_url: product.video_url,
      cat_slug: product.cat_slug,
      cat_name: product.cat_name,
      categories: product.categories,
      extra_product_fields: createProductFields(
        nonNativeFields,
        product.extra_product_fields
      ),
    };

    return request;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let images = cropImgs.filter((url) => url.startsWith("https:"));
    let newImgsAndNames = cropImgs
      .map((url, index) => {
        return { url: url, filename: filenames[index] };
      })
      .filter((ni) => !ni.url.startsWith("https:"));
    if (newImgsAndNames && newImgsAndNames.length > 0) {
      let fnames = newImgsAndNames.map((nif) => nif.filename);
      let imgs = newImgsAndNames.map((nif) => nif.url);
      let imgCloudLocs = await uploadImages(fnames, imgs);
      let newUrls = imgCloudLocs.map((imgLoc) => imgLoc.payload.location);
      images.push(...newUrls);
    }
    let payload = creaetProductRequest(product, images);

    dispatch(addProduct(payload)).then(() => {
      setLoading(true);
      onVariantSave();
    });
  };
  if (expandDesc) {
    return (
      <Fragment>
        <div className={classes.modalStyles}>
          <div className={classes.modalHeader}>
            <Typography variant="h4" className={classes.heading}>
              Description
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: 56 }}>
          <Editor
            editorText={product.description}
            setEditorText={onDescriptionChange}
            height="464px"
          />
        </div>
        <div style={{ height: "100%", position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, right: 0 }}>
            <SolidButton onClick={() => setExpandDesc(false)}>
              Continue
            </SolidButton>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <>
      <div className={classes.modalStyles}>
        <div className={classes.headerBorder}>
          <div className={classes.flexCenterBetween}>
            <div>
              <Typography style={{ fontWeight: 700, fontSize: 20 }}>
                Add Variant
              </Typography>
              <Typography variant="subtitle1">{original.name}</Typography>
            </div>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={onClose}
            >
              <DrawerCloseIcon />
            </IconButton>
          </div>
        </div>

        <Form>
          <div className={classes.flexCenterBetween} style={{ marginTop: 12 }}>
            <p className={classes.subHeading}>Product Image</p>
            {cropImgs.length > 0 && (
              <Button
                size="small"
                color="secondary"
                className={classes.editButton}
                onClick={() => setOpenImageHandler(true)}
              >
                Edit
              </Button>
            )}
          </div>
          <div>
            <div className={classes.flexCenter}>
              <ImageList
                cropImgs={cropImgs}
                setCropImgs={setCropImgs}
                onImageRemove={onImageRemove}
                onImageClick={onImageClick}
              />
              <ImageHandler
                setCropImg={setCropImgs}
                defaultCrop={defaultCrop}
                setFilename={setFilenames}
                cropImg={cropImgs}
                multiple={true}
                filename={filenames}
                maxSize={1920}
                openImageHandler={openImageHandler}
                setOpenImageHandler={setOpenImageHandler}
                openImageHandlerIndex={openImageHandlerIndex}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            </div>
            <p style={{ marginTop: 10 }}>
              Maximum 6 Images. <strong>Drag</strong> thumbnail to re-order
            </p>
          </div>
          <Controls.Input
            name="sale_price"
            label="Sale Price"
            type="number"
            labelPlacement="top"
            labelFontWeight={400}
            startAdornment={<span className={classes.rupee}>₹</span>}
            value={product.sale_price}
            onChange={changeHandler}
            onFocus={onFocus}
            onBlur={validate}
            error={errors.sale_price.error ? errors.sale_price.msg : ""}
          />
          <Controls.Input
            name="mrp"
            label="MRP"
            type="number"
            labelPlacement="top"
            labelFontWeight={400}
            startAdornment={<span className={classes.rupee}>₹</span>}
            placeholder="Optional"
            value={product.mrp}
            onChange={changeHandler}
            error={errors.mrp.error ? errors.mrp.msg : ""}
          />
          {showProductIdAndVideo && (
            <>
              <Controls.Input
                name="product_id"
                label="Product ID"
                labelPlacement="top"
                labelFontWeight={400}
                placeholder="Optional"
                value={product.product_id}
                onChange={changeHandler}
              />
              <Controls.Input
                name="video_url"
                label="Youtube URL"
                labelPlacement="top"
                labelFontWeight={400}
                placeholder="Optional"
                value={product.video_url}
                onChange={changeHandler}
              />
            </>
          )}
          {nonNativeFields.length > 0 &&
            nonNativeFields
              .filter((field) => shouldRenderField(field, categories))
              .map((field) =>
                field.name.toLowerCase() === "color" ||
                field.name.toLowerCase() === "colour" ? (
                  <Controls.ColorInput
                    label={field.name}
                    labelPlacement="top"
                    labelFontWeight={400}
                    name={field.id.toString()}
                    value={formatFieldValue(field)}
                    onChange={changeNonNative}
                  />
                ) : (
                  <Controls.Input
                    name={field.id.toString()}
                    label={field.name}
                    labelPlacement="top"
                    labelFontWeight={400}
                    value={formatFieldValue(field)}
                    onChange={changeNonNative}
                  />
                )
              )}
          <div>
            <p style={{ fontSize: 14 }}>Description</p>
            <Editor
              editorText={product.description}
              setEditorText={onDescriptionChange}
              height="200px"
            />
            <p
              className={classes.editorHelperText}
              onClick={() => setExpandDesc(true)}
            >
              Expand and Edit Field
            </p>
          </div>

          <div className={classes.flexCenterEnd} style={{ marginTop: 20 }}>
            <CombinedButtons
              outlinedBtnAction={onClose}
              solidBtnAction={handleSubmit}
              loading={loading}
            />
          </div>
        </Form>
      </div>
    </>
  );
};
export default VariantForm;

import React from "react";
export function AnnouncementBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <rect
        x="3"
        y="3"
        width="12"
        height="1"
        rx="0.5"
        fill="#666666"
        stroke="#666666"
        stroke-width="0.4"
      />
      <path
        d="M3.19983 6.80017L14.7998 6.80017V14.8002H3.19983L3.19983 6.80017Z"
        fill="#CCCCCC"
        stroke="#CCCCCC"
      />
    </svg>
  );
}

export function SelAnnouncementBar({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect x="3.5" y="3.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <rect
        x="6"
        y="6"
        width="12"
        height="1"
        rx="0.5"
        fill="#1641DB"
        stroke="#1641DB"
        stroke-width="0.4"
      />
      <path
        d="M6.19989 9.80017L17.7999 9.80017V17.8002H6.19989L6.19989 9.80017Z"
        fill="#CCCCCC"
        stroke="#CCCCCC"
      />
    </svg>
  );
}

export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const CONTRACTS_PROGRESS = "CONTRACTS_PROGRESS";
export const SET_CONTRACTS_ERROR = "SET_CONTRACTS_ERROR";
export const SET_CONTRACTS_LOADER = "SET_CONTRACTS_LOADER";
export const CLEAR_CONTRACT = "CLEAR_CONTRACT";
export const CLEAR_CONTRACTS = "CLEAR_CONTRACTS";
export const CLEAR_COMPLETE_CONTRACTS = "CLEAR_COMPLETE_CONTRACTS";
export const SET_CONTRACTS_PER_PAGE = "SET_CONTRACTS_PER_PAGE";
export const SET_CONTRACTS_CURRENT_PAGE = "SET_CONTRACTS_CURRENT_PAGE";
export const SET_CONTRACTS_MAX_PAGE_SO_FAR = "SET_CONTRACTS_MAX_PAGE_SO_FAR";
export const SET_CONTRACTS_INPUT_KEYWORD = "SET_CONTRACTS_INPUT_KEYWORD";
export const SET_CONTRACTS_SEARCH_KEYWORD = "SET_CONTRACTS_SEARCH_KEYWORD";
export const SET_SUCCESS_TOAST = "SET_SUCCESS_TOAST";
export const SET_CONTRACTS_STATE_FILTER = "SET_CONTRACTS_STATE_FILTER";

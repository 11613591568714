import React from "react";
import Form from "./Form/Form";
import HeaderBreadcrumbs from "../../../../../components/common/HeaderBreadcrumbs";

export function CreatePlan() {
  return (
    <div style={{ maxWidth: "600px", margin: "auto" }}>
      <div style={{ padding: "3px 0px 20px 0px" }}>
        <HeaderBreadcrumbs
          list={[
            { label: "Plans", link: "/subscription/plans" },
            {
              label: "Add plan",
            },
          ]}
        />
      </div>
      <Form />
    </div>
  );
}
export default CreatePlan;

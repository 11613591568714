import React from "react";
export function ProductDetailPage({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_27415_34378"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34378)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#CCCCCC" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <mask id="path-4-inside-1_27415_34378" fill="white">
        <rect x="3" y="5" width="8" height="10" rx="0.5" />
      </mask>
      <rect
        x="3"
        y="5"
        width="8"
        height="10"
        rx="0.5"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-4-inside-1_27415_34378)"
      />
      <mask id="path-5-inside-2_27415_34378" fill="white">
        <rect x="12" y="5" width="3" height="4" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="5"
        width="3"
        height="4"
        rx="0.5"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-5-inside-2_27415_34378)"
      />
      <mask id="path-6-inside-3_27415_34378" fill="white">
        <rect x="12" y="10" width="3" height="5" rx="0.5" />
      </mask>
      <rect
        x="12"
        y="10"
        width="3"
        height="5"
        rx="0.5"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-6-inside-3_27415_34378)"
      />
    </svg>
  );
}

export function SelProductDetailPage({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_27444_289"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27444_289)">
        <rect x="3" y="2" width="12" height="1" rx="0.5" fill="#CCCCCC" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <rect x="3" y="5" width="8" height="10" rx="0.5" fill="#1641DB" />
      <rect x="7" y="5" width="4" height="10" rx="0.5" fill="#1641DB" />
      <rect x="12" y="5" width="3" height="4" rx="0.5" fill="#1641DB" />
      <rect x="12" y="10" width="3" height="5" rx="0.5" fill="#1641DB" />
    </svg>
  );
}

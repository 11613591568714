export const sortCriterias = [
  { display_name: "Price: High to Low", query: "salePrice,desc" },
  { display_name: "Price: Low to High", query: "salePrice,asc" },
  { display_name: "Alphabetically", query: "displayName,asc" },
  { display_name: "Quantity: High to Low", query: "qty,desc" },
  { display_name: "Quantity: Low to High", query: "qty,asc" },
];
export const options = [
  { id: "AMOUNT_OFF", title: "Amount Off" },
  { id: "PERCENTAGE", title: "Percentage" },
  { id: "FLAT_RATE", title: "Flat Rate" },
];
export const interval = [
  { id: "DAILY", title: "Daily", frequecy: 1 },
  { id: "ALTERNATE_DAYS", title: "Alternate Days", frequecy: 2 },
  { id: "EVERY_3_DAYS", title: "Every 3 Days ", frequecy: 3 },
  { id: "WEEKLY", title: "Weekly", frequecy: 1 },
  { id: "MONTHLY", title: "Monthly", frequecy: 1 },
  { id: "YEARLY", title: "Yearly", frequecy: 1 },
];

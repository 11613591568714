import React from "react";

const NoRecord = ({
  text,
  image,
  imageUrl,
  imageUrlStyle,
  height = "calc(100vh - 163px)",
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: height,
      }}
    >
      {image && (
        <div
          style={{
            marginTop: "60px",
          }}
        >
          {image}
        </div>
      )}

      {imageUrl && (
        <img
          style={{ marginTop: "50px", ...imageUrlStyle }}
          src={imageUrl}
          alt=""
        />
      )}
      <div style={{ marginTop: "50px" }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "32px",
            color: "#1A1A1A",
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default NoRecord;

import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Error from "./pages/error";
import NewLogin from "./pages/login/NewLogin";
import "./App.css";
import MobileMode from "./components/Layout/MobileMode";
import { getHostedSubPath, getStores } from "./utils/storeUtil";
import { isMobile } from "react-device-detect";
import Subscribe from "./pages/subscribe/Subscribe";
import SubscribeConfirmation from "./pages/subscribe/SubscribeConfirmation";
import RenewalRedirector from "./pages/subscribe/RenewalRedirector";
import RenewConfirmPage from "./pages/subscribe/RenewConfirmPage";
import RenewalPage from "./pages/subscribe/RenewalPage";
import { isCustomDomain } from "./utils/utils";
import CreateAccount from "./pages/login/CreateAccount";
import WalletRecharge from "./pages/wallet/WalletRecharge";
import RechargeSuccess from "./pages/wallet/components/RechargeSuccess";

function App() {
  let isAuthenticated = localStorage.getItem("access_token") ? true : false;
  let subscribeUrl = null;
  const storeUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source") || null;
    const utmMedium = urlParams.get("utm_medium") || null;
    const utmCampaign = urlParams.get("utm_campaign") || null;
    sessionStorage.setItem("utm_source", utmSource);
    sessionStorage.setItem("utm_medium", utmMedium);
    sessionStorage.setItem("utm_campaign", utmCampaign);
  };
  if (/subscribe/.test(window.location.hash)) {
    let url = window.location.hash;
    subscribeUrl = url.replace("#", "");
  }
  if (
    !isAuthenticated &&
    window.location.pathname !== "/admin/login" &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/login/redirect" &&
    !/(subscribe|recharge)/.test(window.location.pathname) &&
    !subscribeUrl
  ) {
    storeUTMParameters();
    window.location.assign(
      window.location.pathname === "/admin" ? "/admin/login" : "/login"
    );
  }
  let stores = getStores();
  let storeId = 0;
  if (stores && stores.length > 0) {
    storeId = stores[0].store_id;
  }

  // const reload = useSelector((state) => state.reload);
  //  if (reload) {
  //    setTimeout(() => {
  //      if (isWindows) {
  //        const scale = window.devicePixelRatio - 0.07;
  //        const elements = document.getElementsByClassName("MuiPopover-paper");
  //        Array.prototype.map.call(elements, (element) => {
  //          const width = element.offsetWidth > 200 ? 200 : element.offsetWidth;
  //          element.style.left = `${parseInt(
  //            element.style.left.slice(0, -2) * scale -
  //              width +
  //              width * ((scale % 1) + 0.07)
  //          )}px`;
  //        });
  //      }
  //    }, 10);
  // }

  // useEffect(() => {
  //   if (isWindows) {
  //     const fraction = 1 / window.devicePixelRatio + 0.07;
  //     document.body.style.zoom = fraction;
  //     const elm = document.createElement("div");
  //     elm.innerHTML = `<style>
  //       #color-popover .MuiPopover-paper,
  //       #color-popover .ColorPicker-MuiPopover-paper {
  //         zoom: ${1 / fraction}
  //       }
  //       .edit-image-modal {
  //         zoom: ${1 / fraction}
  //       }
  //     </style>`;
  //     document.head.appendChild(elm.firstElementChild);
  //   }
  // }, []);

  return (
    <>
      <BrowserRouter>
        {isMobile && (
          <Switch>
            <Route
              path="/subscribe/confirmation/:orderId"
              component={SubscribeConfirmation}
            />
            <Route
              path="/subscribe/renew/:storeId"
              component={RenewalRedirector}
            />
            <Route path="/subscribe/renew" component={RenewalRedirector} />
            <PublicRoute
              path="/:slug/recharge-wallet/:requestId/thank-you"
              component={RechargeSuccess}
            />
            <PublicRoute
              path="/:slug/recharge-wallet"
              component={WalletRecharge}
            />

            <PublicRoute path="/login/redirect" component={NewLogin} />
            <Route path="/subscribe" component={Subscribe} />
            <Route
              path="/:storeId/billing/plans/confirmation/:orderId"
              component={RenewConfirmPage}
            />
            <Route
              path="/:storeId/billing/plans/info"
              component={RenewalPage}
            />
            {!subscribeUrl ? (
              <Route path="/" component={MobileMode} />
            ) : (
              <Redirect to={subscribeUrl} />
            )}
          </Switch>
        )}
        {!isMobile && (
          <Switch>
            <Route
              path="/subscribe/confirmation/:orderId"
              component={SubscribeConfirmation}
            />
            <Route
              path="/subscribe/renew/:storeId"
              component={RenewalRedirector}
            />
            <PublicRoute
              path="/:slug/recharge-wallet/:requestId/thank-you"
              component={RechargeSuccess}
            />
            <PublicRoute
              path="/:slug/recharge-wallet"
              component={WalletRecharge}
            />

            <Route path="/subscribe/renew" component={RenewalRedirector} />
            <Route path="/subscribe" component={Subscribe} />
            {isAuthenticated ? (
              <Route
                exact
                path={["/", "/admin"]}
                render={() =>
                  subscribeUrl ? (
                    <Redirect to={subscribeUrl} />
                  ) : (
                    <Redirect to={getHostedSubPath(storeId, "/dashboard")} />
                  )
                }
              />
            ) : (
              <Route
                exact
                path="/"
                render={() =>
                  subscribeUrl ? (
                    <Redirect to={subscribeUrl} />
                  ) : (
                    <Redirect to={"/login"} />
                  )
                }
              />
            )}
            <PublicRoute path="/admin/login" component={NewLogin} />
            <PublicRoute path="/login" component={NewLogin} />
            <PrivateRoute
              path="/onboarding/create-store"
              component={CreateAccount}
            />
            <PrivateRoute path="/" component={Layout} />
            <Route component={Error} />
          </Switch>
        )}
      </BrowserRouter>
      <style global="true" jsx="true">
        {`
          .MuiOutlinedInput-input {
            font-size: 14px;
            padding: 9px 9px;
          }
          .MuiFormHelperText-contained {
            margin: 2px;
            line-height: 1.35;
          }
          .MuiOutlinedInput-multiline {
            padding: 3px;
          }
          .MuiFormLabel-root {
            font-size: 14px;
          }
        `}
      </style>
    </>
  );
}

function PrivateRoute({ component, ...rest }) {
  const customer = useSelector((state) => state.customer);
  var isAuthenticated = customer.isAuthenticated;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: isCustomDomain() ? "/admin/login" : "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => React.createElement(component, props)}
    />
  );
}

export default App;

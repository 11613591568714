import React from "react";
export function CustomCss({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_27415_34455"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34455)">
        <path
          d="M6.6 13L3 9.5L6.6 6L7.455 6.83125L4.695 9.51458L7.44 12.1833L6.6 13ZM11.4 13L10.545 12.1687L13.305 9.48542L10.56 6.81667L11.4 6L15 9.5L11.4 13Z"
          fill="#666666"
        />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
    </svg>
  );
}

export function SelCustomCss({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="17"
        height="17"
        rx="0.5"
        fill="#1641DB"
        stroke="#1641DB"
      />
      <path
        d="M6.6 13L3 9.5L6.6 6L7.455 6.83125L4.695 9.51458L7.44 12.1833L6.6 13ZM11.4 13L10.545 12.1687L13.305 9.48542L10.56 6.81667L11.4 6L15 9.5L11.4 13Z"
        fill="white"
      />
    </svg>
  );
}

import React from "react";
import Controls from "../../controls/Controls";
import { cardImage, cardImageFallback, cardStyle } from "./styles";

const OptionCard = ({
  option,
  ImageFallback,
  selected,
  onChange,
  disabled,
}) => {
  return (
    <div
      className={cardStyle}
      style={{
        opacity: disabled && !selected ? 0.5 : 1,
        pointerEvents: disabled && !selected ? "none" : "auto",
        padding: "8px",
      }}
    >
      {option.image ? (
        <img
          src={option.image}
          alt={`${option.title} pic`}
          className={cardImage}
        />
      ) : (
        <div className={cardImageFallback}>
          <ImageFallback />
        </div>
      )}
      <div style={{ flexGrow: 1, margin: "0px 8px" }}>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "2%",
            color: "#1a1a1a",
            marginBottom: "4px",
          }}
        >
          {option.title}
        </p>
        <p
          style={{
            lineHeight: "16px",
            fontSize: "12px",
            color: "#1a1a1a",
            margin: "0px",
            letterSpacing: "2%",
          }}
        >
          {option.subTitle}
        </p>
      </div>
      <Controls.Checkbox
        style={{ margin: 0, padding: 0 }}
        checkboxStyles={{ padding: 0, margin: 0 }}
        value={selected}
        disabled={disabled && !selected}
        onChange={(e) => {
          onChange(e.target.checked, option);
        }}
      />
    </div>
  );
};
export default OptionCard;

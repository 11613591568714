import React, { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import FormModal from "../FormModal";
import {
  rowFlexCenterAll,
  rowFlexJustifyBetween,
} from "../../../styles/common";
import { linkText } from "./styles";
import Controls from "../../controls/Controls";
import SearchIcon from "@material-ui/icons/Search";
import OptionCard from "./OptionCard";
import InfiniteScroll from "react-infinite-scroller";
import { addRelatedProducts } from "../../../pages/products/components/RelatedProducts";
export default function SearchableSelect({
  heading,
  subHeading,
  isOpen,
  close,
  initialItems = {},
  ImageFallback = () => <></>,
  fetchData = () => {},
  maxSelectedItem = null,
  hasProduct = false,
  product = null,
  onSaveUpdate = () => {},
  setToast,
  entity = "product",
}) {
  const [selectedItems, setSelectedItems] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [addLoading, setAddLoading] = useState(false);

  const hasChanges = () => {
    const initialKeys = Object.keys(initialItems);
    const currentKeys = Object.keys(selectedItems);
    if (initialKeys.length !== currentKeys.length) return true;
    for (let key of currentKeys) {
      if (!initialItems[key]) return true;
    }
    if (Object.keys(selectedItems).length === 0) {
      return true;
    }
    return false;
  };
  let excludeProductSsku = "";
  if (hasProduct) {
    excludeProductSsku = `&exclude-sskus%5B%5D=${product.super_product_sku}`;
  }
  const handleDataFetch = async (searchTerm = "") => {
    try {
      setLoading(true);
      const { list, response } = await fetchData(
        searchTerm,
        50,
        0,
        excludeProductSsku
      );
      if (product) {
        if (searchTerm) {
          setPage(0);
        } else {
          setPage(page + 1);
        }
        setOptionList(list);
        setHasMore(response.data.payload.totalPages > page + 1);
      } else {
        setOptionList(list);
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setLoading(false);
    }
  };
  const handleSearch = (e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    let id = setTimeout(() => handleDataFetch(e.target.value), 1000);
    setTimeoutId(id);
    setSearchKey(e.target.value);
  };
  const fetchMoreData = async () => {
    const { list, response } = await fetchData(
      searchKey,
      50,
      page,
      excludeProductSsku
    );
    setHasMore(response.data.payload.totalPages > page + 1);
    setPage(page + 1);
    setOptionList((prevList) => [...prevList, ...list]);
  };

  useEffect(() => {
    const filteredSelectedItems = Object.fromEntries(
      Object.entries(initialItems).filter(([key, item]) => !item.auto_generated)
    );
    setSelectedItems(filteredSelectedItems);
    handleDataFetch();

    // eslint-disable-next-line
  }, [initialItems]);

  const onSave = async () => {
    if (hasProduct) {
      try {
        setAddLoading(true);
        const response = await addRelatedProducts(
          product.super_product_sku,
          selectedItems
        );
        if (response.status === 200) {
          setToast({
            open: true,
            severity: "success",
            message: "Products Saved Sucessfully",
          });
          close(selectedItems);
          onSaveUpdate();
        }
      } catch (err) {
        console.log(err);
        setToast({
          open: true,
          severity: "error",
          message: err.message || "An Error Occured Saving Products",
        });
      } finally {
        setAddLoading(false);
      }
    } else {
      close(selectedItems);
    }
  };

  const selectionLimitReached =
    maxSelectedItem && Object.keys(selectedItems).length >= maxSelectedItem;
  const renderOptionCard = (option) => (
    <OptionCard
      key={option.id}
      option={option}
      ImageFallback={ImageFallback}
      selected={
        selectedItems.hasOwnProperty(option.id) &&
        !selectedItems[option.id].auto_generated
      }
      onChange={(checked, option) => {
        const clonedSelectedItems = { ...selectedItems };
        if (option.auto_generated) {
          return;
        }

        if (checked) {
          if (
            maxSelectedItem &&
            Object.keys(clonedSelectedItems).length >= maxSelectedItem
          ) {
            return;
          }
          clonedSelectedItems[option.id] = { ...option };
        } else {
          delete clonedSelectedItems[option.id];
        }
        setSelectedItems(clonedSelectedItems);
      }}
      disabled={option.auto_generated}
      {...(hasProduct && { disabled: selectionLimitReached })}
    />
  );
  return (
    <>
      <FormModal
        heading={heading}
        open={isOpen}
        width={600}
        maxHeight={hasProduct ? 90 : 88}
        disableSave={!hasChanges()}
        overlay={hasProduct ? false : true}
        loading={hasProduct && addLoading}
        onClose={() => {
          close(Object.keys(initialItems).length > 0 ? initialItems : {});
        }}
        onSave={onSave}
        marginBottom={0}
        bottomLeftAdornment={
          hasProduct && (
            <Typography style={{ marginBottom: "11px", fontSize: "14px" }}>
              <b> {Object.keys(selectedItems).length}/10</b> Products selected
            </Typography>
          )
        }
      >
        <div
          style={{
            position: "sticky",
            top: 57,
            background: "#ffffff",
            zIndex: 2,
            paddingTop: hasProduct ? 12 : 0,
          }}
        >
          {!hasProduct && (
            <div className={rowFlexJustifyBetween}>
              <Typography
                className={linkText}
                style={{ fontWeight: 400, marginBottom: "16px" }}
              >
                {subHeading}
              </Typography>
              <p
                style={{
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#1641DB",
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginBottom: "12px",
                }}
                onClick={() => {
                  setSelectedItems({});
                }}
              >
                Clear All
              </p>
            </div>
          )}
          <Controls.Input
            placeholder="Search by name"
            value={searchKey}
            onChange={handleSearch}
            endAdornment={<SearchIcon />}
          />
        </div>
        {loading && (
          <div
            style={{
              width: "100%",
              height: product ? "calc(82vh - 234px)" : "133px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        {optionList.length === 0 && !loading && (
          <div
            style={{
              width: "100%",
              height: product ? "calc(82vh - 234px)" : "133px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>No {entity} found</p>
          </div>
        )}
        {hasProduct && !loading && optionList.length > 0 ? (
          <div
            style={{
              height: "calc(82vh - 234px)",
              overflowY: "auto",
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchMoreData}
              hasMore={hasMore}
              useWindow={false}
              loader={
                <div style={{ marginTop: 20, textAlign: "center" }} key={0}>
                  <CircularProgress fontSize="small" color="blue" />
                </div>
              }
            >
              {!loading && optionList.map(renderOptionCard)}
            </InfiniteScroll>
          </div>
        ) : (
          !loading && optionList.map(renderOptionCard)
        )}

        {searchKey === "" && !loading && !hasProduct && (
          <div className={rowFlexCenterAll} style={{ marginTop: "10px" }}>
            <Typography
              style={{ fontWeight: 400, fontSize: "12px", color: "#1641DB" }}
            >
              Use Search box above to find more products
            </Typography>
          </div>
        )}
      </FormModal>
    </>
  );
}

import React, { useEffect, useState } from "react";
import useStyles from "../orders/styles";
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCarts,
  clearCarts,
  setCartDateSelector,
  setCartStatusSelector,
  setCartDateRange,
  setCartsPerPage,
  setCartsCurrentPage,
  setCartsMaxPageSoFar,
  setCartsInputKeyword,
  setCartsSearchKeyword,
} from "../../redux/carts/cartActions";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Moment from "react-moment";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Paginator from "../../components/Paginator/Paginator";
import {
  CARTS_PER_PAGE,
  CARTS_KEY,
  DEFAULT_CARTS_PER_PAGE,
  getDateRange,
} from "../../utils/utils";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Fragment } from "react";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import PageLoader from "../../components/Layout/PageLoader";
import NoOrderFigma from "../../icons/NoOrderFigma";
import ViewCart from "./components/ViewCart";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import PaidPopUp from "../../components/PaidPopUp";
import { BASIC } from "../plans/constants";
import { useHistory, useLocation } from "react-router-dom";
import NoRecord from "../../components/common/NoRecord";

const Carts = () => {
  const classes = useStyles();
  const history = useHistory();
  const store = useSelector((state) => state.store.store);
  const cartState = useSelector((state) => state.carts);
  const carts = cartState.carts;
  const numberOfPages = cartState.numberOfPages;
  const loader = cartState.loader;
  const dateSelector = cartState.dateSelector;
  const statusSelector = cartState.statusSelector;
  const dateRange = cartState.dateRange;
  const cartsPerPage = cartState.cartsPerPage;
  const currentPage = cartState.currentPage;
  const maxPageSoFar = cartState.maxPageSoFar;
  const searchKeyword = cartState.searchKeyword;
  const inputKeyword = cartState.inputKeyword;

  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [anchoredCart, setAnchoredCart] = useState(null);
  const [openViewCart, setOpenViewCart] = useState(false);
  const [isPaidPopUpOpen, setIsPaidPopUpOpen] = useState(false);

  const params = new URLSearchParams(useLocation().search);
  const filterQuery = params.get("filter") ? params.get("filter") : null;
  const deStructureFQ = (fq) => {
    if (fq === "today") return "Today";
    if (fq === "yesterday") return "Yesterday";
    if (fq === "last-week") return "Last Week";
    if (fq === "last-month") return "Last Month";
  };

  const onChangeCartsPerPage = (updatedCartsPerPage) => {
    localStorage.setItem(CARTS_KEY, updatedCartsPerPage);
    dispatch(clearCarts());
    dispatch(setCartsCurrentPage(0));
    dispatch(setCartsMaxPageSoFar(0));
    dispatch(setCartsPerPage(updatedCartsPerPage));
    dispatch(
      fetchCarts(
        0,
        textToStatus(statusSelector),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        updatedCartsPerPage
      )
    );
  };

  useEffect(() => {
    if (!cartsPerPage) {
      dispatch(setCartsPerPage(CARTS_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [cartsPerPage]);

  let startIndex = currentPage * cartsPerPage;
  let endIndex =
    startIndex + cartsPerPage < carts.length
      ? startIndex + cartsPerPage
      : carts.length;
  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();

  useEffect(() => {
    if (
      store &&
      store.plan &&
      (store.plan === "FREE" || store.plan === "REFER")
    ) {
      setIsPaidPopUpOpen(true);
    } else if (
      carts.length === 0 &&
      !dateSelector &&
      !statusSelector &&
      !filterQuery
    ) {
      dispatch(setCartDateSelector("All"));
      dispatch(setCartStatusSelector("All"));
      dispatch(setCartDateRange({ aoe: null, boe: null }));
      dispatch(fetchCarts());
    } else if (filterQuery) {
      let range = deStructureFQ(filterQuery);
      let res = getDateRange(range);
      let aoe = res.aoe;
      let boe = res.boe;
      dispatch(setCartDateSelector(range));
      dispatch(setCartStatusSelector("All"));
      dispatch(setCartDateRange({ aoe: aoe, boe: boe }));
      dispatch(setCartsSearchKeyword(""));
      dispatch(setCartsInputKeyword(""));
      dispatch(setCartsMaxPageSoFar(0));
      dispatch(setCartsCurrentPage(0));
      dispatch(fetchCarts(0, "All", aoe, boe, null, cartsPerPage));
    }
    // eslint-disable-next-line
  }, [storeId, store, filterQuery]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchCarts(
          currentPage + 1,
          textToStatus(statusSelector),
          dateRange.aoe,
          dateRange.boe,
          searchKeyword,
          cartsPerPage
        )
      );
      dispatch(
        setCartsMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setCartsCurrentPage(currentPage + 1));
    } else {
      dispatch(setCartsCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setCartsCurrentPage(currentPage - 1));
  };

  const handleChangeDateSelector = (range) => {
    setWhiteSpaceDiv(true);
    if (range === dateSelector) return;
    if (filterQuery && filterQuery !== range) {
      history.replace(`/${storeId}/carts`);
    }
    dispatch(setCartsMaxPageSoFar(0));
    dispatch(setCartsCurrentPage(0));
    let res = getDateRange(range);
    let aoe = res.aoe;
    let boe = res.boe;
    dispatch(
      fetchCarts(
        0,
        textToStatus(statusSelector),
        aoe,
        boe,
        searchKeyword,
        cartsPerPage
      )
    );
    dispatch(setCartDateSelector(range));
    dispatch(setCartDateRange({ aoe: aoe, boe: boe }));
  };

  const handleCustomDateSelector = (rangePicker) => {
    setWhiteSpaceDiv(true);
    dispatch(setCartsMaxPageSoFar(0));
    dispatch(setCartsCurrentPage(0));
    dispatch(
      fetchCarts(
        0,
        textToStatus(statusSelector),
        rangePicker.startDate._d.toISOString(),
        rangePicker.endDate._d.toISOString(),
        searchKeyword,
        cartsPerPage
      )
    );
    dispatch(setCartDateSelector("Custom"));
    dispatch(
      setCartDateRange({
        aoe: rangePicker.startDate._d.toISOString(),
        boe: rangePicker.endDate._d.toISOString(),
      })
    );
  };

  const textToStatus = (text) => {
    if (text === "Pending") return "PENDING";
    if (text === "Completed") return "COMPLETEED";
    return "ALL";
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setCartsMaxPageSoFar(0));
      dispatch(setCartsCurrentPage(0));
      dispatch(setCartStatusSelector("All"));
      dispatch(setCartDateSelector("All"));
      dispatch(setCartDateRange({ aoe: null, boe: null }));
      dispatch(fetchCarts(0, "All", null, null, keyword, cartsPerPage));
    }
  };
  if (
    store &&
    store.plan &&
    (store.plan === "FREE" || store.plan === "REFER")
  ) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Abandoned Carts" }]} />
              <NoRecord
                text={
                  <div>
                    Please{" "}
                    <span
                      className={classes.upgradeLink}
                      onClick={() => setIsPaidPopUpOpen(true)}
                    >
                      Upgrade Plan
                    </span>{" "}
                    to view Abandoned Carts
                  </div>
                }
                image={<NoOrderFigma />}
                arrow={false}
              />
            </div>
          </Grid>
        </Grid>
        <PaidPopUp
          open={isPaidPopUpOpen}
          close={() => setIsPaidPopUpOpen(false)}
          plan={BASIC}
          subtxt="View the list of carts that have been left out by the customers as abandoned"
        />
      </div>
    );
  }

  if (cartState.progress && carts.length === 0) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Abandoned Carts" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={2}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader} style={{ paddingTop: 7 }}>
              <HeaderBreadcrumbs list={[{ label: "Abandoned Carts" }]} />
            </div>
            {carts.length === 0 &&
            statusSelector === "All" &&
            dateSelector === "All" &&
            searchKeyword === "" ? (
              <NoRecord
                text="No Abandoned Carts for your store"
                image={<NoOrderFigma />}
                arrow={false}
              />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Enter Customer Name, Mobile"
                        className={classes.searchBox}
                        value={inputKeyword}
                        onChange={(e) =>
                          dispatch(setCartsInputKeyword(e.target.value))
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(
                                setCartsSearchKeyword(inputKeyword.trim())
                              );
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setCartsSearchKeyword(""));
                            dispatch(setCartsInputKeyword(""));
                            dispatch(setCartsMaxPageSoFar(0));
                            dispatch(setCartsCurrentPage(0));
                            dispatch(
                              fetchCarts(
                                0,
                                "ALL",
                                null,
                                null,
                                null,
                                cartsPerPage
                              )
                            );
                            dispatch(setCartStatusSelector("All"));
                            dispatch(setCartDateSelector("All"));
                            dispatch(
                              setCartDateRange({ aoe: null, boe: null })
                            );
                          }
                        }}
                      />
                      {searchKeyword !== "" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setCartsSearchKeyword(""));
                            dispatch(setCartsInputKeyword(""));
                            dispatch(setCartsMaxPageSoFar(0));
                            dispatch(setCartsCurrentPage(0));
                            dispatch(
                              fetchCarts(
                                0,
                                "ALL",
                                null,
                                null,
                                null,
                                cartsPerPage
                              )
                            );
                            dispatch(setCartStatusSelector("All"));
                            dispatch(setCartDateSelector("All"));
                            dispatch(
                              setCartDateRange({ aoe: null, boe: null })
                            );
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div className={classes.tableHeaderRight}>
                      <div>
                        <DateRangePicker
                          initialSettings={{
                            startDate: new Date(
                              new Date().setMonth(new Date().getMonth() - 1)
                            ),
                            endDate: new Date(),
                            maxDate: new Date(),
                            applyButtonClasses: classes.applyBtn,
                          }}
                          onApply={(fn, rangePicker) => {
                            handleCustomDateSelector(rangePicker);
                          }}
                        >
                          <Typography
                            className={classes.dateFilter}
                            style={{
                              color:
                                dateSelector === "Custom"
                                  ? "#1641DB"
                                  : "#666666",
                              fontSize: 12,
                              fontWeight: dateSelector === "Custom" ? 600 : 400,
                              cursor: "pointer",
                            }}
                          >
                            Custom
                          </Typography>
                        </DateRangePicker>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("All");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "All" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "All" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          All
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Today");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Today" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "Today" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Today
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Yesterday");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Yesterday"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Yesterday" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Yesterday
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Week");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Week"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Week" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Week
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Month");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Month"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Month" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Month
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "10%" }}>Cart #</TableCell>
                          <TableCell style={{ width: "15%" }}>
                            Create Date
                          </TableCell>
                          <TableCell style={{ width: "10%" }}>Amount</TableCell>
                          <TableCell style={{ width: "15%" }}>
                            Customer Name
                          </TableCell>
                          <TableCell style={{ width: "14%" }}>
                            Customer Mobile
                          </TableCell>
                          <TableCell style={{ width: "13%" }}>
                            Approx City
                          </TableCell>
                          <TableCell style={{ width: "13%" }}>
                            Approx Pincode
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {carts
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % cartsPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <span
                                  style={{
                                    color: "#1641DB",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setAnchoredCart(tableitem);
                                    setOpenViewCart(true);
                                  }}
                                >
                                  CRT-{tableitem.id}
                                </span>
                              </TableCell>
                              <TableCell>
                                <Moment format="DD-MMM-YYYY, HH:mm">
                                  {tableitem.created_at}
                                </Moment>
                              </TableCell>
                              <TableCell>
                                {(
                                  tableitem.sale_price +
                                  tableitem.shipping_charge +
                                  tableitem.packaging_charge -
                                  tableitem.coupon_discount
                                ).toFixed(2)}
                              </TableCell>
                              <TableCell>{tableitem.customer_name}</TableCell>
                              <TableCell>{tableitem.customer_mobile}</TableCell>
                              <TableCell>{tableitem.geo?.city ?? ""}</TableCell>
                              <TableCell>
                                {tableitem.geo?.pincode ?? ""}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {cartState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: cartsPerPage * 68 - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {carts.length === 0 && (
                      <div
                        style={{
                          height: CARTS_PER_PAGE * 68 - 4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Abandoned Carts Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeCartsPerPage}
                  possibleRecordsPerPage={[DEFAULT_CARTS_PER_PAGE, 20, 50, 100]}
                  recordsPerPage={cartsPerPage}
                  totalCount={cartState.numberOfElements}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {anchoredCart && openViewCart && (
        <ViewCart
          openViewCart={openViewCart}
          setOpenViewCart={setOpenViewCart}
          setAnchoredCart={setAnchoredCart}
          cart={anchoredCart}
        />
      )}
    </div>
  );
};
export default Carts;

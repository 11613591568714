import {
  FETCH_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  PRODUCT_PROGRESS,
  SET_PRODUCT_ERROR,
  CLEAR_PRODUCT,
  PRODUCT_LOADER,
  CLEAR_COMPLETE_PRODUCT,
  SET_FILTER_SLUGS,
  SET_SORT_CRITERIA,
  SET_BULK_IMPORT_TOAST,
  SET_FILTER_STATUS,
  SET_FILTER_QUANTITY,
  SET_PRODUCTS_PER_PAGE,
  SET_PRODUCT_ONLINE,
  SET_FILTER_PAYMENT_MODE,
} from "./productTypes";
import axios from "axios";
import { getDefaultStore, getStoreIdFromUrl } from "../../utils/storeUtil";

export const fetchCurrentProduct = async (sku) => {
  try {
    let storeId = getStoreIdFromUrl();
    let accessToken = localStorage.getItem("access_token");

    let prodResp = await axios.get(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product/find-by-sku/${sku}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return prodResp.data.payload;
  } catch (error) {
    console.error("Failed to fetch super product:", error);
    throw error;
  }
};

export const fetchSuperProduct = async (product) => {
  try {
    let storeId = getStoreIdFromUrl();
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/org/${product.org_id}/stores/${storeId}/super-products/${product.super_product_sku}`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );

    return response.data.payload;
  } catch (error) {
    console.error("Failed to fetch super product:", error);
    throw error;
  }
};

export const fetchProducts =
  (
    page,
    size,
    keyword,
    categories,
    sort,
    status,
    payment_mode,
    minQty,
    maxQty
  ) =>
  (dispatch) => {
    dispatch({
      type: PRODUCT_PROGRESS,
      payload: true,
    });
    let store = getDefaultStore();
    let catParam = "";
    if (categories && categories.length > 0) {
      categories.forEach((cat) => {
        catParam = catParam + `&cat-slugs%5B%5D=${cat}`;
      });
    }
    let statusParam = "";
    if (status === "online") {
      statusParam = "&online-only=true";
    } else if (status === "offline") {
      statusParam = "&offline-only=true";
    }
    let paymentModeParam = "";
    if (payment_mode === "cod") {
      paymentModeParam = "&payment-mode=COD";
    } else if (payment_mode === "prepaid") {
      paymentModeParam = "&payment-mode=ONLINE";
    }
    let minParam = minQty != null ? `&min-stock-qty=${minQty}` : "";
    let maxParam = maxQty != null ? `&max-stock-qty=${maxQty}` : "";
    fetch(
      `${process.env.REACT_APP_API}/api/v1/org/products?${
        keyword && keyword !== "" ? `q=${keyword}&` : ""
      }org-id=${
        store.org_id
      }&child-cat-products=true&skip-description=true&page=${page}&size=${size}&sort=${sort}${catParam}${statusParam}${paymentModeParam}${minParam}${maxParam}`,
      {}
    )
      .then((res) => res.json())
      .then((products) =>
        dispatch({
          type: FETCH_PRODUCTS,
          payload: {
            records: products.payload,
            page: page,
            productsPerPage: size,
          },
        })
      );
  };

export const addProduct =
  (productData, productsPerPage) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    dispatch({
      type: PRODUCT_LOADER,
      payload: true,
    });
    let storeId = getStoreIdFromUrl();
    let data = productData;
    return await axios
      .post(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: ADD_PRODUCT,
          payload: response.data.payload,
          productsPerPage,
        });
        return response.data.payload;
      })
      .catch((error) => {
        dispatch({
          type: SET_PRODUCT_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const editProduct =
  (productData, productsPerPage) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    let storeId = getStoreIdFromUrl();

    return await axios
      .post(
        `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/product`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: response.data.payload,
          productsPerPage,
        });
        return response.data.payload;
      })
      .catch((error) => {
        console.log(error.response);
        dispatch({
          type: SET_PRODUCT_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const deleteProduct = (skus, productsPerPage) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  startLoader();
  let storeId = getStoreIdFromUrl();
  return await axios
    .delete(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/products`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        data: skus,
      }
    )
    .then(() =>
      dispatch({
        type: DELETE_PRODUCT,
        payload: skus,
        productsPerPage,
      })
    )
    .catch((error) => {
      dispatch({
        type: SET_PRODUCT_ERROR,
        payload: { error: error.message, skus: skus },
      });
    });
};

export const onlineOfflineProducts = (skus, value) => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  startLoader();
  let storeId = getStoreIdFromUrl();
  return await axios
    .patch(
      `${process.env.REACT_APP_API}/api/v2/business/store/${storeId}/products?attr=online&value=${value}`,
      skus,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      dispatch({
        type: SET_PRODUCT_ONLINE,
        payload: { skus, value },
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_PRODUCT_ERROR,
        payload: { error: error.message, skus: skus },
      });
    });
};

export const clearProductError = () => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_ERROR,
    payload: { error: null },
  });
};

export const setProductError = (message) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_ERROR,
    payload: { error: message },
  });
};

export const clearProduct = () => (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCT,
    payload: null,
  });
};

export const clearProductData = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_PRODUCT,
  });
};

export const startLoader = () => (dispatch) => {
  dispatch({
    type: PRODUCT_LOADER,
    payload: true,
  });
};

export const setFilterSlugs = (filterSlugs) => (dispatch) => {
  dispatch({
    type: SET_FILTER_SLUGS,
    payload: { filterSlugs },
  });
};

export const setFilterStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_FILTER_STATUS,
    payload: { status },
  });
};

export const setFilterMode = (mode) => (dispatch) => {
  dispatch({
    type: SET_FILTER_PAYMENT_MODE,
    payload: { mode },
  });
};

export const setFilterQty = (minQty, maxQty) => (dispatch) => {
  dispatch({
    type: SET_FILTER_QUANTITY,
    payload: { minQty, maxQty },
  });
};

export const setSortCriteria = (sortBy) => (dispatch) => {
  dispatch({
    type: SET_SORT_CRITERIA,
    payload: { sortBy },
  });
};

export const setBulkToast = (data) => (dispatch) => {
  dispatch({
    type: SET_BULK_IMPORT_TOAST,
    payload: { data },
  });
};

export const setProductsPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_PRODUCTS_PER_PAGE,
    payload: data,
  });
};

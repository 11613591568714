import React from "react";
export function Banner({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_27415_34351"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27415_34351)">
        <rect x="3" y="3" width="12" height="2" rx="0.5" fill="#CCCCCC" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <mask id="path-4-inside-1_27415_34351" fill="white">
        <rect x="2.69983" y="6.55005" width="12.6" height="8.7" rx="0.5" />
      </mask>
      <rect
        x="2.69983"
        y="6.55005"
        width="12.6"
        height="8.7"
        rx="0.5"
        stroke="#666666"
        stroke-width="2"
        mask="url(#path-4-inside-1_27415_34351)"
      />
    </svg>
  );
}

export function SelBanner({ fill = "#666666", strokeWidth = 0 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_27444_261"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_27444_261)">
        <rect x="3" y="3" width="12" height="2" rx="0.5" fill="#CCCCCC" />
      </g>
      <rect x="0.5" y="0.5" width="17" height="17" rx="0.5" stroke="#CCCCCC" />
      <rect
        x="2.69983"
        y="6.55005"
        width="12.6"
        height="8.7"
        rx="0.5"
        fill="#1641DB"
      />
    </svg>
  );
}

import { Divider, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../styles";
import {
  rowFlexAlignCenter,
  rowFlexJustifyBetween,
} from "../../../../../styles/common";
import ProductNoPicIcon from "../../../../../icons/NoPicIcon";
import SubcriptionIcon from "../../../../../icons/SubcriptionIcon";
import DeliveryIcon from "../../../../../icons/DeliveryIcon";

const SubscriptionDetails = ({ contractDetails }) => {
  const classes = useStyles();
  const Discount = contractDetails?.discount_type === "PERCENTAGE" ? "%" : "₹";

  const discountValue = () => {
    const discountInRupees =
      (contractDetails?.sale_price - contractDetails?.discounted_price) *
      contractDetails?.quantity;
    if (contractDetails?.discount_type === "PERCENTAGE") {
      return (
        currencyFormat.format(discountInRupees / contractDetails?.sale_price) *
        100
      ).toFixed(2);
    } else {
      return currencyFormat.format(discountInRupees);
    }
  };
  const currencyFormat = new Intl.NumberFormat("en-IN", {
    maximumFractionDigits: 2,
  });
  return (
    <>
      <div className={rowFlexJustifyBetween}>
        <Typography className={classes.heading} style={{ marginBottom: 0 }}>
          Subscription Details
        </Typography>
      </div>

      <div style={{ marginTop: 16, display: "flex", alignItems: "center" }}>
        {contractDetails.product_image ? (
          <img
            className={classes.prodimage}
            src={contractDetails.product_image}
            alt="Thumbnail"
          />
        ) : (
          <div className={classes.nopicctn}>
            <ProductNoPicIcon />
          </div>
        )}
        <div style={{ width: "84%", marginLeft: 14 }}>
          <Typography className={classes.heading}>
            {contractDetails.product_name}
          </Typography>
          <div className={classes.flexBox}>
            <div>
              <Typography
                className={classes.subHeading}
                style={{
                  fontWeight: "normal",
                  marginTop: 8,
                  color: "#666666",
                  paddingBottom: 5,
                }}
              >
                Sale Price :{" "}
                <b>₹{currencyFormat.format(contractDetails.sale_price)}</b>
              </Typography>
            </div>
            <div>
              <Typography
                className={classes.subHeading}
                style={{
                  fontWeight: "normal",
                  marginTop: 8,
                  color: "#666666",
                  paddingBottom: 5,
                }}
              >
                Discounted price :{" "}
                <b>
                  ₹{currencyFormat.format(contractDetails.discounted_price)}
                </b>
              </Typography>
            </div>
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography
                className={classes.subHeading}
                style={{
                  fontWeight: "normal",
                  marginTop: 8,
                  color: "#666666",
                  paddingBottom: 5,
                }}
              >
                Qty{" "}
                <b>
                  ₹{currencyFormat.format(contractDetails.discounted_price)} x{" "}
                  {contractDetails.quantity}
                  {""} = ₹
                  {currencyFormat.format(
                    contractDetails.discounted_price * contractDetails.quantity
                  )}
                </b>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Divider style={{ margin: "15px 0px 20px 0px" }} />
      <div className={rowFlexAlignCenter} style={{ gap: 12 }}>
        <div className={rowFlexAlignCenter}>
          <Typography className={classes.subHeading}>
            <DeliveryIcon /> Delivery:{" "}
            <b>
              {contractDetails?.repeat_every === 2
                ? "Alternate Days"
                : contractDetails?.repeat_every === 3
                ? "Every 3 Days"
                : contractDetails?.interval.charAt(0).toUpperCase() +
                  contractDetails?.interval.slice(1).toLowerCase()}
            </b>
          </Typography>
        </div>
        <div className={rowFlexAlignCenter}>
          <Typography className={classes.subHeading}>
            <SubcriptionIcon color={"#1641DB"} /> Discount:{" "}
            <b>
              {Discount === "%" ? "" : "₹"}
              {discountValue()} {Discount === "%" ? "%" : ""} off
            </b>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetails;

import { style } from "typestyle";

export const cardImageFallback = style({
  height: 40,
  width: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
export const linkText = style({
  fontSize: "14px",
  color: "#1A1A1A",
  fontWeight: 600,
  lineHeight: "20px",
});
export const cardStyle = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "1px solid #ebebeb",
  padding: "12px",
  borderRadius: "6px",
  margin: "4px 0px",
});

export const cardImage = style({
  width: 40,
  height: 40,
  objectFit: "scale-down",
  borderRadius: "4px",
});

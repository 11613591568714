import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
  fetchEstimates,
  startEstimateLoader,
  stopEstimateLoader,
  clearEstimates,
  deleteEstimate,
  setEstimateDateSelector,
  setEstimateStatusSelector,
  setEstimateDateRange,
  setEstimatesPerPage,
  setEstimatesCurrentPage,
  setEstimatesMaxPageSoFar,
  setEstimatesInputKeyword,
  setEstimatesSearchKeyword,
  convertEstToInv,
  setEstimatesCustomerId,
} from "../../redux/estimateOrders/estimateActions";
import DownArrowIcon from "../../icons/DownArrowIcon";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Moment from "react-moment";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { downloadDocument } from "../../utils/documentUtil";
import {
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";
import Paginator from "../../components/Paginator/Paginator";
import {
  ESTIMATES_PER_PAGE,
  ESTIMATES_KEY,
  DEFAULT_ESTIMATES_PER_PAGE,
  getDateRange,
} from "../../utils/utils";
import StatusDotIcon from "../../icons/StatusDotIcon";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Fragment } from "react";
import { getPdfLink } from "../../utils/invoiceUtil";
import { getStoreIdFromUrl } from "../../utils/storeUtil";
import {
  setReloadFalse,
  setReloadTrue,
} from "../../redux/reload/reloadActions";
import ConfirmModal from "../../components/common/ConfirmModal";
import Toast from "../../components/Layout/Toast";
import {
  anchor,
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
} from "../../styles/common";
import SolidButton from "../../components/buttons/SolidButton";
import PageLoader from "../../components/Layout/PageLoader";
import NoEstimateFigma from "../../icons/NoEstimateFigma";
import { getWhatsAppShareLink } from "../../utils/orderUtil";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import { useQuery } from "../coupons/Components/CouponForm/formUtils";
import NoRecord from "../../components/common/NoRecord";

const Estimates = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQuery();
  const estimateState = useSelector((state) => state.estimates);
  const store = useSelector((state) => state.store);
  const estimates = estimateState.estimates;
  const numberOfPages = estimateState.numberOfPages;
  const loader = estimateState.loader;
  const dateSelector = estimateState.dateSelector;
  const statusSelector = estimateState.statusSelector;
  const dateRange = estimateState.dateRange;
  const estimatesPerPage = estimateState.estimatesPerPage;
  const currentPage = estimateState.currentPage;
  const maxPageSoFar = estimateState.maxPageSoFar;
  const searchKeyword = estimateState.searchKeyword;
  const inputKeyword = estimateState.inputKeyword;
  const customerId = estimateState.customerId;

  const [anchorEl, setAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [anchoredOrder, setAnchoredOrder] = useState(null);

  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");

  const [isDeleting, setIsDeleting] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [pageProgress, setPageProgress] = useState(false);

  const refetch = queryParams.get("refetch");
  const paramCustomerId = queryParams.get("customer-id");

  const onChangeEstimatesPerPage = (updatedEstimatesPerPage) => {
    localStorage.setItem(ESTIMATES_KEY, updatedEstimatesPerPage);
    dispatch(clearEstimates());
    dispatch(setEstimatesCurrentPage(0));
    dispatch(setEstimatesMaxPageSoFar(0));
    dispatch(setEstimatesPerPage(updatedEstimatesPerPage));
    dispatch(
      fetchEstimates(
        0,
        textToStatus(statusSelector),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        updatedEstimatesPerPage
      )
    );
  };

  let startIndex = currentPage * estimatesPerPage;
  let endIndex =
    startIndex + estimatesPerPage < estimates.length
      ? startIndex + estimatesPerPage
      : estimates.length;

  const dispatch = useDispatch();
  const storeId = getStoreIdFromUrl();

  useEffect(() => {
    if (!estimatesPerPage) {
      dispatch(setEstimatesPerPage(ESTIMATES_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [estimatesPerPage]);

  useEffect(() => {
    if (
      refetch ||
      (estimates.length === 0 && !dateSelector && !statusSelector)
    ) {
      setPageProgress(true);
      dispatch(setEstimateDateSelector("All"));
      dispatch(setEstimateStatusSelector("All"));
      dispatch(setEstimateDateRange({ aoe: null, boe: null }));
      if (paramCustomerId || customerId) {
        dispatch(
          setEstimatesCustomerId(paramCustomerId ? paramCustomerId : customerId)
        );
        dispatch(
          fetchEstimates(
            0,
            "All",
            null,
            null,
            null,
            ESTIMATES_PER_PAGE,
            paramCustomerId ? paramCustomerId : customerId
          )
        ).then(() => {
          setPageProgress(false);
        });
      } else {
        dispatch(fetchEstimates()).then(() => {
          setPageProgress(false);
        });
      }
    }
    // eslint-disable-next-line
  }, [storeId, refetch]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchEstimates(
          currentPage + 1,
          textToStatus(statusSelector),
          dateRange.aoe,
          dateRange.boe,
          searchKeyword,
          estimatesPerPage,
          customerId
        )
      );
      dispatch(
        setEstimatesMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setEstimatesCurrentPage(currentPage + 1));
    } else {
      dispatch(setEstimatesCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setEstimatesCurrentPage(currentPage - 1));
  };

  const handleClick = (event, tableitem) => {
    setAnchoredOrder(tableitem);
    setAnchorEl(event.currentTarget);
  };

  const handleChangeDateSelector = (range) => {
    setWhiteSpaceDiv(true);
    if (range === dateSelector) return;
    dispatch(setEstimatesMaxPageSoFar(0));
    dispatch(setEstimatesCurrentPage(0));
    let res = getDateRange(range);
    let aoe = res.aoe;
    let boe = res.boe;
    dispatch(
      fetchEstimates(
        0,
        textToStatus(statusSelector),
        aoe,
        boe,
        searchKeyword,
        estimatesPerPage,
        customerId
      )
    );
    dispatch(setEstimateDateSelector(range));
    dispatch(setEstimateDateRange({ aoe: aoe, boe: boe }));
  };

  const handleCustomDateSelector = (rangePicker) => {
    setWhiteSpaceDiv(true);
    dispatch(setEstimatesMaxPageSoFar(0));
    dispatch(setEstimatesCurrentPage(0));
    dispatch(
      fetchEstimates(
        0,
        textToStatus(statusSelector),
        rangePicker.startDate._d.toISOString(),
        rangePicker.endDate._d.toISOString(),
        searchKeyword,
        estimatesPerPage,
        customerId
      )
    );
    dispatch(setEstimateDateSelector("Custom"));
    dispatch(
      setEstimateDateRange({
        aoe: rangePicker.startDate._d.toISOString(),
        boe: rangePicker.endDate._d.toISOString(),
      })
    );
  };

  const handleChangeStatusSelector = (status) => {
    setWhiteSpaceDiv(true);
    dispatch(setEstimatesMaxPageSoFar(0));
    dispatch(setEstimatesCurrentPage(0));
    dispatch(
      fetchEstimates(
        0,
        textToStatus(status),
        dateRange.aoe,
        dateRange.boe,
        searchKeyword,
        estimatesPerPage,
        customerId
      )
    );
    dispatch(setEstimateStatusSelector(status));
    setStatusAnchorEl(null);
  };

  const statusToText = (status) => {
    if (status === "CONFIRMED") return { text: "Pending", fill: "#FF0000" };
    if (status === "COMPLETE") return { text: "Completed", fill: "#72C472" };
    return { text: "-", fill: "white" };
  };

  const textToStatus = (text) => {
    if (text === "Pending") return "CONFIRMED";
    if (text === "Completed") return "COMPLETE";
    return "ALL";
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setEstimatesMaxPageSoFar(0));
      dispatch(setEstimatesCurrentPage(0));
      dispatch(setEstimateStatusSelector("All"));
      dispatch(setEstimateDateSelector("All"));
      dispatch(setEstimateDateRange({ aoe: null, boe: null }));
      dispatch(
        fetchEstimates(
          0,
          "All",
          null,
          null,
          keyword,
          estimatesPerPage,
          customerId
        )
      );
    }
  };

  const viewPdf = () => {
    dispatch(startEstimateLoader());
    getPdfLink(anchoredOrder.id).then((link) => {
      window.open(link);
      dispatch(stopEstimateLoader());
    });
  };

  const whatsAppShare = async () => {
    dispatch(startEstimateLoader());
    await getWhatsAppShareLink(anchoredOrder).then((link) => {
      window.open(link);
      dispatch(stopEstimateLoader());
    });
  };

  const downloadInvoice = async () => {
    let filename = anchoredOrder.invoice_id + ".pdf";
    dispatch(startEstimateLoader());
    await downloadDocument(store.store, "estimate", anchoredOrder.id, filename);
    dispatch(stopEstimateLoader());
  };

  const handleDelete = () => {
    dispatch(startEstimateLoader());
    dispatch(deleteEstimate(anchoredOrder.id));
  };

  const handleConvertToInvoice = () => {
    dispatch(startEstimateLoader());
    dispatch(convertEstToInv(anchoredOrder.id)).then(() => {
      setMessage("Estimate Converted to Invoice successfully");
      setToast(true);
    });
  };

  if (pageProgress || (estimateState.progress && estimates.length === 0)) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Estimates" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={2}>
        {loader && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              zIndex: "900",
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        )}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Estimates" }]} />
              <SolidButton
                style={{ width: 185 }}
                onClick={() => {
                  history.push("./estimates/create");
                }}
              >
                Create Estimate
              </SolidButton>
            </div>
            {estimates.length === 0 &&
            statusSelector === "All" &&
            dateSelector === "All" &&
            searchKeyword === "" &&
            !customerId ? (
              <NoRecord
                text="Start creating Estimates for your customers"
                image={<NoEstimateFigma />}
                arrow={true}
              />
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Enter Estimate Number, Customer Name, Mobile"
                        className={classes.searchBox}
                        value={
                          customerId
                            ? `Customer ID: ${customerId}`
                            : inputKeyword
                        }
                        onChange={(e) =>
                          dispatch(setEstimatesInputKeyword(e.target.value))
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(
                                setEstimatesSearchKeyword(inputKeyword.trim())
                              );
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setEstimatesSearchKeyword(""));
                            dispatch(setEstimatesInputKeyword(""));
                            dispatch(setEstimatesMaxPageSoFar(0));
                            dispatch(setEstimatesCurrentPage(0));
                            dispatch(
                              fetchEstimates(
                                0,
                                "ALL",
                                null,
                                null,
                                null,
                                estimatesPerPage,
                                customerId
                              )
                            );
                            dispatch(setEstimateStatusSelector("All"));
                            dispatch(setEstimateDateSelector("All"));
                            dispatch(
                              setEstimateDateRange({ aoe: null, boe: null })
                            );
                          }
                        }}
                      />
                      {(searchKeyword !== "" || customerId) && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setEstimatesSearchKeyword(""));
                            dispatch(setEstimatesInputKeyword(""));
                            dispatch(setEstimatesMaxPageSoFar(0));
                            dispatch(setEstimatesCurrentPage(0));
                            dispatch(fetchEstimates());
                            dispatch(setEstimateStatusSelector("All"));
                            dispatch(setEstimateDateSelector("All"));
                            dispatch(setEstimatesCustomerId(null));
                            dispatch(
                              setEstimateDateRange({ aoe: null, boe: null })
                            );
                            if (customerId) history.push(`./estimates`);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div className={classes.tableHeaderRight}>
                      <div>
                        <DateRangePicker
                          initialSettings={{
                            startDate: new Date(
                              new Date().setMonth(new Date().getMonth() - 1)
                            ),
                            endDate: new Date(),
                            maxDate: new Date(),
                            applyButtonClasses: classes.applyBtn,
                          }}
                          onApply={(fn, rangePicker) => {
                            handleCustomDateSelector(rangePicker);
                          }}
                        >
                          <Typography
                            className={classes.dateFilter}
                            style={{
                              color:
                                dateSelector === "Custom"
                                  ? "#1641DB"
                                  : "#666666",
                              fontSize: 12,
                              fontWeight: dateSelector === "Custom" ? 600 : 400,
                              cursor: "pointer",
                            }}
                          >
                            Custom
                          </Typography>
                        </DateRangePicker>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("All");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "All" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "All" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          All
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Today");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Today" ? "#1641DB" : "#666666",
                            fontWeight: dateSelector === "Today" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Today
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Yesterday");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Yesterday"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Yesterday" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Yesterday
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Week");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Week"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Week" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Week
                        </Typography>
                      </div>
                      <div
                        onClick={() => {
                          handleChangeDateSelector("Last Month");
                        }}
                      >
                        <Typography
                          className={classes.dateFilter}
                          style={{
                            color:
                              dateSelector === "Last Month"
                                ? "#1641DB"
                                : "#666666",
                            fontWeight:
                              dateSelector === "Last Month" ? 600 : 400,
                            fontSize: 12,
                            cursor: "pointer",
                          }}
                        >
                          Last Month
                        </Typography>
                      </div>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          dispatch(setReloadTrue());
                          setStatusAnchorEl(e.currentTarget);
                        }}
                        className={classes.statusSelector}
                        style={{ width: 105, outline: "none" }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Typography
                              style={{
                                fontWeight: 400,
                                fontSize: 10,
                                textAlign: "left",
                              }}
                            >
                              Status
                            </Typography>
                            <Typography
                              style={{
                                fontWeight: 600,
                                fontSize: 12,
                                textAlign: "left",
                              }}
                            >
                              {statusSelector}
                            </Typography>
                          </div>
                          <DownArrowIcon stroke="#1A1A1A" />
                        </div>
                      </Button>
                      <Menu
                        anchorEl={statusAnchorEl}
                        keepMounted
                        open={Boolean(statusAnchorEl)}
                        onClose={() => {
                          dispatch(setReloadFalse());
                          setStatusAnchorEl(null);
                        }}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("All");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "All"}
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("Pending");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "Pending"}
                        >
                          Pending
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleChangeStatusSelector("Completed");
                          }}
                          style={{ fontSize: 12, color: "#1641DB" }}
                          selected={statusSelector === "Completed"}
                        >
                          Completed
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "18%" }}>
                            Estimate #
                          </TableCell>
                          <TableCell style={{ width: "12%" }}>Amount</TableCell>
                          <TableCell style={{ width: "12%" }}>
                            Balance
                          </TableCell>
                          <TableCell style={{ width: "13%" }}>
                            Create Date
                          </TableCell>
                          <TableCell style={{ width: "17%" }}>
                            Customer Name
                          </TableCell>
                          <TableCell style={{ width: "10%" }}>Status</TableCell>
                          <TableCell style={{ width: "11%" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {estimates
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % estimatesPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>
                                <Link
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                  to={`./estimates/${encodeURIComponent(
                                    tableitem.invoice_id
                                  )}`}
                                >
                                  {tableitem.invoice_id}
                                </Link>
                              </TableCell>
                              <TableCell>
                                {tableitem.final_sale_price.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {tableitem.due_amount.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                <Moment format="DD-MMM-YYYY">
                                  {tableitem.created_at}
                                </Moment>
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`./customers/${tableitem.org_store_customer_id}`}
                                  style={{
                                    color: "#1641DB",
                                    textDecoration: "none",
                                  }}
                                >
                                  {tableitem.org_customer_name}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <StatusDotIcon
                                    fill={statusToText(tableitem.state).fill}
                                  />
                                  &nbsp;&nbsp;
                                  {statusToText(tableitem.state).text}
                                </div>
                              </TableCell>
                              <TableCell align="center">
                                <Box display="flex" justifyContent="flex-end">
                                  <Box>
                                    <MoreHorizIcon
                                      style={{ cursor: "pointer" }}
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => {
                                        dispatch(setReloadTrue());
                                        handleClick(event, tableitem);
                                      }}
                                    />
                                    <Menu
                                      id="simple-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={Boolean(anchorEl)}
                                      onClose={() => {
                                        dispatch(setReloadFalse());
                                        setAnchorEl(null);
                                      }}
                                      className={anchor}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          viewPdf();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        View
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          downloadInvoice();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        Download
                                      </MenuItem>
                                      {anchoredOrder &&
                                        anchoredOrder.state !== "COMPLETE" && (
                                          <MenuItem
                                            onClick={() => {
                                              setIsConverting(true);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            Convert to Invoice
                                          </MenuItem>
                                        )}
                                      <MenuItem
                                        onClick={() => {
                                          whatsAppShare();
                                          setAnchorEl(null);
                                        }}
                                      >
                                        WhatsApp Share
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          setIsDeleting(true);
                                        }}
                                      >
                                        Delete
                                      </MenuItem>

                                      {/*<MenuItem
                                    onClick={() => {
                                      printPdf();
                                      setAnchorEl(null);
                                    }}
                                  >
                                    Print
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      setAnchorEl(null);
                                    }}
                                  >
                                    Share
                                  </MenuItem>*/}
                                    </Menu>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {estimateState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: estimatesPerPage * 68 - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {estimates.length === 0 && (
                      <div
                        style={{
                          height: ESTIMATES_PER_PAGE * 68 - 4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Estimate Order Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeEstimatesPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_ESTIMATES_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  totalCount={estimateState.numberOfElements}
                  recordsPerPage={estimatesPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <ConfirmModal
        open={isDeleting}
        message={
          anchoredOrder
            ? `Are you sure want to delete Estimate ${anchoredOrder.invoice_id} ?`
            : ""
        }
        closeBtnText="Cancel"
        confirmBtnText="Delete"
        onClose={() => {
          setIsDeleting(false);
          setAnchorEl(null);
        }}
        onConfirm={() => {
          handleDelete();
          setAnchorEl(null);
          setIsDeleting(false);
        }}
      />
      <ConfirmModal
        open={isConverting}
        message={
          anchoredOrder
            ? `Are you sure want to convert Estimat ${anchoredOrder.invoice_id} to Invoice ?`
            : ""
        }
        closeBtnText="Cancel"
        confirmBtnText="Convert"
        intent="positive"
        onClose={() => {
          setIsConverting(false);
          setAnchorEl(null);
        }}
        onConfirm={() => {
          handleConvertToInvoice();
          setAnchorEl(null);
          setIsConverting(false);
        }}
      />
      <Toast open={toast} close={setToast} message={message} />
    </div>
  );
};
export default Estimates;

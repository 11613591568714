import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DocumentIcon = ({ viewBox = "0 0 20 20", fill = "currentColor" }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: "20px", height: "20px" }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.875 0.625H3.125C2.43464 0.625 1.875 1.18464 1.875 1.875V18.125C1.875 18.8154 2.43464 19.375 3.125 19.375H16.875C17.5654 19.375 18.125 18.8154 18.125 18.125V1.875C18.125 1.18464 17.5654 0.625 16.875 0.625Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 6.25C5.69036 6.25 6.25 5.69036 6.25 5C6.25 4.30964 5.69036 3.75 5 3.75C4.30964 3.75 3.75 4.30964 3.75 5C3.75 5.69036 4.30964 6.25 5 6.25Z"
          fill={fill}
        />
        <path
          d="M5 16.25C5.69036 16.25 6.25 15.6904 6.25 15C6.25 14.3096 5.69036 13.75 5 13.75C4.30964 13.75 3.75 14.3096 3.75 15C3.75 15.6904 4.30964 16.25 5 16.25Z"
          fill={fill}
        />
        <path
          d="M5 11.25C5.69036 11.25 6.25 10.6904 6.25 10C6.25 9.30964 5.69036 8.75 5 8.75C4.30964 8.75 3.75 9.30964 3.75 10C3.75 10.6904 4.30964 11.25 5 11.25Z"
          fill={fill}
        />
        <path
          d="M14.375 8.125H9.375V11.875H14.375V8.125Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default DocumentIcon;

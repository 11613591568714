import React from "react";
import { Modal, IconButton } from "@material-ui/core";
import { CloseIcon } from "../../icons";
import CombinedButtons from "../buttons/CombinedButtons";

const ConfirmModal = ({
  children,
  open,
  message,
  closeBtnText = "Cancel",
  confirmBtnText = "Confirm",
  onClose,
  onConfirm,
  intent = "negative",
  title = "",
  solidFirst = false,
  confirmBtnStyle = {},
  disabled = false,
  combinedButtons = 0,
  loading,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: 25,
          borderRadius: 12,
          paddingBottom: 16,
          position: "absolute",
          background: "#ffffff",
          width: 500,
        }}
      >
        {title && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 32,
            }}
          >
            <p style={{ fontSize: 24, fontWeight: 600, marginBottom: 0 }}>
              {title}
            </p>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {children ? children : <p style={{ fontSize: 16 }}>{message}</p>}
        <div
          style={{
            height: "50px",
            position: "relative",
            marginTop: 50,
            marginBottom: 7,
          }}
        >
          <div
            style={{ position: "absolute", bottom: combinedButtons, right: 0 }}
          >
            <CombinedButtons
              outlinedBtnAction={onClose}
              outlinedBtnText={closeBtnText}
              solidBtnAction={onConfirm}
              solidBtnText={confirmBtnText}
              loading={loading}
              isDelete={intent === "negative"}
              containerCss={solidFirst ? { flexDirection: "row-reverse" } : {}}
              solidBtnStyle={{
                backgroundColor: disabled ? "lightgrey" : "blue",
                color: disabled ? "darkgrey" : "white",
                cursor: disabled ? "not-allowed" : "pointer",
                ...confirmBtnStyle,
              }} // Style for confirm button
              style={{
                width: 163,
                marginRight: solidFirst ? 16 : 0,
                // ...confirmBtnStyle,
              }}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmModal;

import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { isWindows } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
  paginator: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  selector: {
    borderBottom: "1px solid #1641DB",
    color: "#1641DB",
    fontSize: 14,
  },
  menuitem: {
    fontSize: 14,
  },
  paginatorRight: {
    display: "flex",
    alignItems: "center",
  },
  counter: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #999999",
    borderRadius: "6px",
    textAlign: "center",
    width: 36,
    height: 32,
    color: "#999999",
    lineHeight: "16px",
    marginLeft: "20px",
    marginRight: "20px",
    alignItems: "center",
    alignContent: "center",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#FFFFFF",
    textAlign: "center",
    border: "1px solid #1641DB",
    color: "#1641DB",
    fontSize: "12px",
    width: 67,
    lineHeight: "16px",
    textTransform: "none",
    borderRadius: "6px",
    boxShadow: "none !important",
    height: 34,
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  disabledButton: {
    border: "1px solid #eeeeee",
  },
  customFill: {
    fill: "#1641DB",
  },
  paginatorLeft: {
    color: "#999999",
    // marginLeft: "40px",
    fontSize: "14px",
    lineHeight: "18px",
  },
  starticon: {
    marginRight: 0,
  },
  endicon: {
    marginLeft: 0,
  },
}));
const Paginator = ({
  onNext,
  onPrev,
  total,
  currentPage,
  totalCount,
  recordsPerPage,
  marginLeft,
  marginTop,
  possibleRecordsPerPage,
  changeRecordsPerPage,
  show = false,
  showPagination = true,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    changeRecordsPerPage(event.target.value);
  };
  const [reload, setReload] = useState(false);

  if (reload) {
    setTimeout(() => {
      if (isWindows) {
        let scale = window.devicePixelRatio - 0.07;
        let elements = document.getElementsByClassName("MuiPopover-paper");
        Array.prototype.map.call(elements, (element) => {
          element.style.top = `${parseInt(
            element.style.top.slice(0, -2) * scale -
              element.offsetHeight * (scale % 1)
          )}px`;
        });
      }
    }, 10);
  }
  return (
    <div
      className={classes.paginator}
      style={{ marginTop: marginTop ? marginTop : "24px" }}
    >
      <Select
        labelId="num-records-select-label"
        id="num-records-select"
        value={recordsPerPage}
        onChange={handleChange}
        autoWidth
        disableUnderline
        className={classes.selector}
        inputProps={{ classes: { icon: classes.customFill } }}
        onOpen={() => {
          setReload(true);
        }}
        onClose={() => {
          setReload(false);
        }}
      >
        {possibleRecordsPerPage.map((numRecords, idx) => {
          return (
            <MenuItem
              value={numRecords}
              key={`select_options${idx}`}
              className={classes.menuitem}
            >
              {numRecords} rows
            </MenuItem>
          );
        })}
      </Select>
      {showPagination && (
        <Typography
          className={classes.paginatorLeft}
          style={{ marginLeft: marginLeft }}
        >
          {currentPage + 1} of {total} {total > 1 ? "Pages" : "Page"}
          {totalCount
            ? ` - ${totalCount} ${totalCount > 1 ? "Items" : "Item"}`
            : ""}
        </Typography>
      )}

      <div className={classes.paginatorRight}>
        <Button
          variant="contained"
          disabled={currentPage === 0}
          startIcon={<KeyboardArrowLeftIcon />}
          classes={{
            startIcon: classes.starticon,
            disabled: classes.disabledButton,
          }}
          className={classes.button}
          onClick={onPrev}
          style={{ outline: "none" }}
        >
          Prev
        </Button>
        {show && <Button>{currentPage}</Button>}
        <Button
          variant="contained"
          endIcon={<KeyboardArrowRightIcon />}
          className={classes.button}
          classes={{
            endIcon: classes.endicon,
            disabled: classes.disabledButton,
          }}
          disabled={currentPage + 1 === total}
          onClick={onNext}
          style={{ outline: "none", marginLeft: 10 }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default Paginator;

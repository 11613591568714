import React, { useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Controls from "../../../components/controls/Controls";
import RenderDataTypeFields from "./DataTypeFields";
import CombinedButtons from "../../../components/buttons/CombinedButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdditionalField,
  editAdditionalField,
} from "../../../redux/additionalFields/additionalFieldsActions";
import {
  fieldDataType,
  getAdditionalFields,
  makeSettingsField,
  productFiledTypes,
} from "./ProductHelper";
import useMultiSelectCategory from "./hooks/useMultiSelectCategory";
import Toast from "../../../components/Layout/Toast";
import { PLANS } from "../../plans/constants";
import PaidPopUp from "../../../components/PaidPopUp";

const link =
  "https://www.shoopy.in/post/optimizing-your-e-commerce-store-the-power-of-product-variants-and-extra-checkout-fields";

const useStyles = makeStyles(() => ({
  rowFlexAlign: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },

  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E1E1E1",
    paddingBottom: 10,
  },

  addFeild: {
    fontSize: 20,
    fontWeight: 700,
  },
  help: {
    fontSize: 14,
    fontWeight: 600,
    color: "#1641DB",
  },
  combinedButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: "10px",
    paddingBottom: 10,
  },
}));

const AddAdditionalField = ({
  record,
  setToast,
  setMessage,
  setOpenCustomField,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { categoryOptions } = useSelector((state) => state.categories);

  const initialState = {
    productFieldType: {
      value: record?.group || "none",
      error: null,
    },
    fieldName: {
      value: record?.name || "",
      error: null,
    },
    description: {
      value: record?.description || "",
      error: null,
    },

    fieldDataType: {
      value: record?.field_type || "none",
      error: null,
    },

    textLimit: {
      value: record?.settings?.text_length || "",
      error: null,
    },
    documentType: {
      value: record?.settings?.types?.includes("DOCUMENT") || false,
      error: null,
    },
    imageType: {
      value: record?.settings?.types?.includes("IMAGE") || false,
      error: null,
    },

    listOptions: {
      value: record?.settings?.list_options || "",
      error: null,
    },

    fileError: {
      value: false,
      error: null,
    },

    minBufferPeriod: {
      value: record?.min_buffer || 0,
      error: null,
    },
    maxBufferPeriod: {
      value: record?.max_buffer || 7,
      error: null,
    },
    minTimeRange: {
      value: record?.min_time || "00:00",
      error: null,
    },
    maxTimeRange: {
      value: record?.max_time || "23:59",
      error: null,
    },

    categories: {
      value:
        record?.categories?.map(({ cat_slug, cat_name }) => ({
          id: cat_slug,
          title: cat_name,
        })) || [],
      error: null,
    },

    required: {
      value: record?.required !== false,
      error: null,
    },
    hideFromFilter: {
      value: record?.hide_from_filter,
      error: null,
    },
  };
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [openCategoryField, setOpenCategoryField] = useState(
    record?.categories?.length ? true : false
  );
  const [categoryList, setCategoryList] = useState(formData.categories.value);
  const [selectedCategories, lastSelectedCategory, renderChips] =
    useMultiSelectCategory(categoryList, record?.cat_slug);

  const [error, setError] = useState(null);
  const [isPaidPopUpOpen, setIsPaidPopUpOpen] = useState(false);
  const [openPaidPopUpForPlan, setPpenPaidPopUpForPlan] = useState("FREE");

  const storeData = useSelector((state) => state.store);
  const storePlan = storeData?.store.plan;

  const checkIfThisFeatureAvailableInCurrPlan = (plan) => {
    if (storePlan) {
      const plans = Object.keys(PLANS);
      const featurePlanIndex = plans.findIndex((e) => e === plan);
      const storePlanIndex = plans.findIndex((e) => e === storePlan);
      return featurePlanIndex <= storePlanIndex;
    } else {
      return false;
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    let newValue;
    let errors = {};

    if (type === "checkbox") {
      newValue = checked;
    } else if (
      name === "productFieldType" &&
      value === "CHECKOUT" &&
      !checkIfThisFeatureAvailableInCurrPlan("BUSINESS")
    ) {
      setIsPaidPopUpOpen(true);
      setPpenPaidPopUpForPlan("BUSINESS");
      return;
    } else if (name === "fieldName") {
      newValue = value.slice(0, 50);
    } else if (name === "description") {
      newValue = value.slice(0, 120);
    } else if (name === "textLimit") {
      newValue = value.match(/^\d*$/) ? value : formData[name].value;
    } else if (name === "minBufferPeriod" || name === "maxBufferPeriod") {
      newValue = value;
      const otherFieldName =
        name === "minBufferPeriod" ? "maxBufferPeriod" : "minBufferPeriod";
      const otherFieldValue = formData[otherFieldName].value;

      if (
        (name === "minBufferPeriod" &&
          parseInt(value) >= parseInt(otherFieldValue)) ||
        (name === "maxBufferPeriod" &&
          parseInt(value) <= parseInt(formData["minBufferPeriod"].value))
      ) {
        const errorMsg =
          name === "minBufferPeriod"
            ? "Min buffer period must be less than max allowed duration"
            : "Max allowed duration must be greater than min buffer period";

        errors[name] = errorMsg;
        errors[otherFieldName] = errorMsg;
      }
    } else if (name === "minTimeRange" || name === "maxTimeRange") {
      newValue = value;
      const otherFieldName =
        name === "minTimeRange" ? "maxTimeRange" : "minTimeRange";
      const otherFieldValue = formData[otherFieldName].value;
      if (value === otherFieldValue) {
        const errorMsg = "Start and end times cannot be the same";
        errors[name] = errorMsg;
        errors[otherFieldName] = errorMsg;
      }
    } else {
      newValue = value;
    }

    let updates = {};

    updates[name] = {
      ...formData[name],
      value: newValue,
      error: errors[name] || null,
    };

    Object.keys(errors).forEach((field) => {
      if (field !== name) {
        updates[field] = {
          ...formData[field],
          error: errors[field],
        };
      }
    });
    setFormData((data) => ({
      ...data,
      ...updates,
    }));
  };

  const handleCategories = (event, newValue) => {
    setCategoryList(newValue);
  };

  const onFocus = (event) => {
    const { name } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        error: "",
      },
    }));
  };

  const onBlur = (event) => {
    const { name } = event.target;
    setFormData((prevState) => {
      const formattedName = name
        .replace(/(?!^)([A-Z])/g, " $1")
        .replace(/\b\w/g, (letter) => letter.toUpperCase())
        .trim();

      const isError =
        prevState[name].value === "none" || !prevState[name].value;
      const errorMessage = isError ? `${formattedName} is required.` : null;

      return {
        ...prevState,
        [name]: {
          ...prevState[name],
          error: errorMessage,
        },
      };
    });
  };

  const validation = () => {
    let isValid = true;

    Object.keys(formData).forEach((key) => {
      if (formData[key].error) {
        isValid = false;
      }
    });

    let newFormData = { ...formData };

    const baseFields = ["productFieldType", "fieldName"];

    const additionalFields = getAdditionalFields(formData);

    const fieldsToValidate = [...baseFields, ...additionalFields];

    let booleanGroupFields = [];

    fieldsToValidate.forEach((fieldName) => {
      const fieldValue = formData[fieldName]?.value;
      const isString = typeof fieldValue === "string";
      const isEmpty = isString ? !fieldValue.trim() : false;

      if (!["documentType", "imageType"].includes(fieldName)) {
        if (!formData[fieldName] || isEmpty || fieldValue === "none") {
          const errorMessage = `${fieldName
            .replace(/(?!^)([A-Z])/g, " $1")
            .replace(/\b\w/g, (letter) => letter.toUpperCase())
            .trim()} is required.`;

          newFormData[fieldName] = {
            ...formData[fieldName],
            error: errorMessage,
          };

          isValid = false;
        }
      } else {
        if (formData[fieldName]) {
          booleanGroupFields.push(fieldName);
        }
      }
    });

    if (booleanGroupFields.length > 0) {
      const isAnyTrue = booleanGroupFields.some(
        (fieldName) => formData[fieldName] && formData[fieldName].value === true
      );
      if (!isAnyTrue) {
        newFormData.fileError = {
          value: true,
          error: "At least one document type (Document or Image) is required.",
        };

        isValid = false;
      }
    }
    setFormData(newFormData);

    return isValid;
  };

  const makePayload = () => {
    const payload = {
      ...(record && {
        id: record.id,
      }),
      group: formData.productFieldType?.value,
      name: formData.fieldName?.value,
      description: formData.description?.value,
      cat_name: selectedCategories.length ? lastSelectedCategory?.title : "",
      cat_slug: selectedCategories.length ? lastSelectedCategory?.id : "",
      categories:
        selectedCategories.length > 0
          ? selectedCategories.map((category) => ({
              cat_slug: category.id,
              cat_name: category.title,
            }))
          : [],
      ...(formData["productFieldType"].value === "CHECKOUT" && {
        settings: makeSettingsField(formData),
      }),
      ...(formData["productFieldType"].value === "CHECKOUT" &&
        formData.fieldDataType?.value !== "none" && {
          field_type: formData.fieldDataType?.value,
        }),
      required:
        formData["productFieldType"].value === "VARIANT"
          ? false
          : formData.required.value,
      hide_from_filter:
        (formData["productFieldType"].value === "VARIANT" ||
          formData["productFieldType"].value === "ATTRIBUTE") &&
        formData.hideFromFilter.value,
      native: false,
    };

    return payload;
  };

  const onCancel = () => {
    setOpenCustomField(false);
  };

  const onSave = async () => {
    const isValid = validation();
    if (!isValid) return;
    let payload = makePayload();
    setLoading(true);
    try {
      const action = record ? editAdditionalField : addAdditionalField;
      const response = await dispatch(action(payload));
      if (response?.data) {
        setToast(true);
        setMessage(`Field ${record ? "updated" : "added"} successfully.`);
      }
      setOpenCustomField(false);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.headerContainer}>
          <div className={classes.rowFlexAlign} style={{ marginLeft: -14 }}>
            <IconButton
              aria-label="Close"
              aria-haspopup="true"
              color="inherit"
              onClick={onCancel}
            >
              <ArrowBackIcon />
            </IconButton>
            <div className={classes.addFeild}>{`${
              record ? "Edit" : "Add"
            } Field`}</div>
          </div>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <div className={classes.rowFlexAlign}>
              <HelpOutlineIcon
                fontSize={"small"}
                style={{ color: "#1641DB" }}
              />
              <div className={classes.help}>Help</div>
            </div>
          </a>
        </div>
        <div
          style={{
            marginTop: 20,
          }}
        >
          <div
            style={{ marginBottom: formData.productFieldType.error ? 20 : 0 }}
          >
            <Controls.Select
              name="productFieldType"
              label="Product Field Type"
              labelPlacement="top"
              value={formData.productFieldType.value}
              onChange={handleChange}
              onFocus={onFocus}
              options={productFiledTypes}
              placeholder={"Select Field Type"}
              helperText={
                !formData.productFieldType.error &&
                "Allows additional information to be included on the product description page."
              }
              error={formData.productFieldType.error}
              disabled={record?.group}
            />
          </div>

          <Controls.Input
            name="fieldName"
            label="Field Name"
            labelPlacement="top"
            value={formData.fieldName.value}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={
              formData.fieldName.error ||
              "Input the field name that helps identify its purpose or content."
            }
            error={!!formData.fieldName.error}
            extraSpace={!!formData.fieldName.error}
          />

          <Controls.Input
            name="description"
            label="Description (optional)"
            labelPlacement="top"
            value={formData.description.value}
            onChange={handleChange}
            onFocus={onFocus}
            helperText="Input the field name that helps identify its purpose or content."
          />

          {formData.productFieldType.value === "CHECKOUT" && (
            <>
              <div
                style={{
                  marginBottom: formData.fieldDataType.error ? 20 : 0,
                }}
              >
                <Controls.Select
                  name="fieldDataType"
                  label="Field Data Type"
                  labelPlacement="top"
                  value={formData.fieldDataType.value}
                  onChange={handleChange}
                  onFocus={onFocus}
                  options={fieldDataType}
                  placeholder={"Select Field Data Type"}
                  helperText={
                    !formData.fieldDataType.error &&
                    "Choose the type to determine the action for text field."
                  }
                  error={formData.fieldDataType.error}
                />
              </div>

              <RenderDataTypeFields
                filedType={formData.fieldDataType.value}
                formData={formData}
                onFocus={onFocus}
                onBlur={onBlur}
                handleChange={handleChange}
              />
            </>
          )}

          {formData["productFieldType"].value === "CHECKOUT" && (
            <Controls.Switch
              name="required"
              label="Make field mandatory"
              value={formData.required.value}
              onChange={handleChange}
              helperText={
                "Enabling this option will make this field mandatory. Customers must fill the field to add products to their cart."
              }
              helperTextWidth={440}
              style={{ borderTop: "1px solid #E1E1E1", paddingTop: 10 }}
            />
          )}

          <Controls.Switch
            name="showFieldInCategory"
            label="Show Field in Category"
            value={openCategoryField}
            onChange={() => setOpenCategoryField(!openCategoryField)}
            helperText={
              "Enabling this option will cause fields to appear only on selected categories."
            }
            style={{ borderTop: "1px solid #E1E1E1", paddingTop: 10 }}
          />

          {openCategoryField && (
            <Controls.Checkboxes
              name="categories"
              label={<></>}
              labelPlacement="top"
              placeholder="Categories"
              selectedOptions={selectedCategories}
              options={categoryOptions}
              onChange={handleCategories}
              renderTags={renderChips}
              helperText="Fields will only appear in the category you choose here."
            />
          )}
          {(formData["productFieldType"].value === "ATTRIBUTE" ||
            formData["productFieldType"].value === "VARIANT") && (
            <Controls.Switch
              name="hideFromFilter"
              label="Do not show in Products Filter Menu"
              value={formData.hideFromFilter.value}
              checked={formData.hideFromFilter.value}
              onChange={handleChange}
              helperText={
                "Hide this field from filter menu on your Online Store's products listing and category pages"
              }
              style={{ borderTop: "1px solid #E1E1E1", paddingTop: 10 }}
            />
          )}
        </div>
      </div>
      <div className={classes.combinedButtons}>
        <CombinedButtons
          loading={loading}
          disabled={loading}
          solidBtnAction={onSave}
          outlinedBtnAction={onCancel}
        />
      </div>
      <Toast open={!!error} close={setError} message={error} severity="error" />
      <PaidPopUp
        open={isPaidPopUpOpen}
        close={() => setIsPaidPopUpOpen(false)}
        plan={openPaidPopUpForPlan}
        subtxt="View the list of carts that have been left out by the customers as abandoned"
      />
    </div>
  );
};

export default AddAdditionalField;

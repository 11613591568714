import axios from "axios";
import { getDefaultStore } from "./storeUtil";

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export const uploadImages = async (filenames, images, errCallback) => {
  let imgCloudLocs = [];
  let index = 0;
  for (const filename of filenames) {
    let img = images[index];
    try {
      let cloudFile = await uploadImage(filename, img);
      imgCloudLocs.push(cloudFile);
    } catch (error) {
      errCallback &&
        errCallback("Some Images are rejected, Max 2MB is allowed to upload!");
    }
    index++;
  }
  return imgCloudLocs;
};

export const uploadImage = async (filename, encodedImg) => {
  let file = await urltoFile(encodedImg, filename, "image/png");
  let store = getDefaultStore();
  let formData = new FormData();
  formData.append("file", file);
  let accessToken = localStorage.getItem("access_token");
  let config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
  let port = process.env.REACT_APP_LOCAL_PORT;
  let host = process.env.REACT_APP_LOCAL_HOST;
  let proto = process.env.REACT_APP_LOCAL_PROTO;
  let url = `${proto}://${host}:${port}/api/v1/cloud/file/pub?cloud-file-name=${filename}&cloud-file-loc=${store.org_id}`;
  let response = await axios.post(url, formData, config);
  return response.data;
};
